import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { Select, Popover, Button } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { Option } from "types/Options";
import ProductForm from "../product-form/product-form";
import { fetch as fetchProducts } from "../products/products.service";
import "./style.scss";

type Props = {
  label?: string;
  value: string;
  onChange: Function;
  isRequired?: boolean;
};

function ProductSelect({ label, value, onChange, isRequired }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [productOptions, setProductsOptions] = useState<Array<Option>>(
    []
  );
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [isActiveProductForm, setIsActiveProductForm] = useState(false);
  const toggleActiveProductForm = useCallback(
    () => setIsActiveProductForm((isActiveProductForm) => !isActiveProductForm),
    []
  );
  const [selected, setSelected] = useState("");

  const fetchData = useCallback(
    async (id?: string) => {
      try {
        setIsLoading(true);

        const { productsCollection } = await fetchProducts();
        const productOptions: Array<Option> = _.map(
          productsCollection,
          ({ id, title }) => ({ label: title, value: id })
        );

        productOptions.unshift({ label: "-", value: "" });

        setProductsOptions(productOptions);
        if (id) {
          setSelected(id);
          onChange(id);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    },
    [onChange]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = (val: string) => {
    if (isRequired && !val) {
      setError(t("field_is_required"));
    } else {
      setError("");
    }

    onChange(val);
  };

  const activator = (
    <Button onClick={toggleActiveProductForm}>{t("create_product")}</Button>
  );

  return (
    <div className="product-select">
      <Select
        label={label ? t(label) : ""}
        options={productOptions}
        onChange={handleChange}
        value={selected || value}
        disabled={isLoading}
        requiredIndicator={isRequired}
        error={t(error)}
      />
      <div className="product-select__add">
        <Popover
          active={isActiveProductForm}
          activator={activator}
          autofocusTarget="first-node"
          onClose={toggleActiveProductForm}
        >
          <ProductForm onSubmit={(id) => fetchData(id)} isInPopover />
        </Popover>
      </div>
    </div>
  );
}

export default ProductSelect;
