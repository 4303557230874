import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Checkbox,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchById } from "services/user-select.service";
import UploadFile from "../upload-file/upload-file";
import Files from "../files/files";
import { submit } from "./user-form.slice";

function UserForm({ id }) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [viewFiles, setViewFiles] = useState();
  const [files, setFiles] = useState();
  const [isDisabled, setIsDisabled] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [externalCode, setExternalCode] = useState();

  const { t } = useTranslation();
  const { userId, companyId } = useParams();
  const { isLoading } = useSelector((state) => state.userFormReducer);

  // const handleFormChange = (formData) => dispatch(setFormData(formData));
  const handleFormSubmit = async () => {
    const formData = {
      userId,
      email,
      files,
      isDisabled,
      name,
      phone,
      externalCode,
      company: companyId,
    };

    await dispatch(submit(companyId, formData));
  };
  const handleEmailChange = useCallback((value) => setEmail(value), []);
  const handleIsDisabledChange = useCallback(
    (value) => setIsDisabled(value),
    []
  );
  const handleFilesChange = useCallback((value) => setFiles(value), []);
  const isFormValid = () => email;

  useEffect(() => {
    async function fetchData() {
      try {
        const { userEntity } = await fetchById(userId, companyId);
        setEmail(userEntity.email);
        setViewFiles(userEntity.files);
        setIsDisabled(userEntity.isDisabled);
      } catch (e) {
        // console.error(e);
      }
    }
    if (userId) {
      fetchData();
    }
  }, [userId, companyId]);

  const filesNode = viewFiles ? <Files files={viewFiles} /> : "";
  const filesForm = userId ? (
    <div>
      {filesNode}
      <UploadFile onChange={handleFilesChange} />
    </div>
  ) : (
    ""
  );

  const submitNode = userId ? (
    <Button loading={isLoading} disabled={!isFormValid()} submit>
      {t("update_user")}
    </Button>
  ) : (
    <Button loading={isLoading} disabled={!isFormValid()} submit>
      {t("create_user")}
    </Button>
  );

  return (
    <div className="user-form">
      <Form className="user-form_form" onSubmit={handleFormSubmit}>
        <FormLayout>
          <TextField
            disabled={userId}
            label={t("Email")}
            type="email"
            requiredIndicator
            autoComplete="off"
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            disabled={userId}
            label={t("name")}
            type="text"
            autoComplete="off"
            value={name}
            onChange={setName}
          />
          <TextField
            disabled={userId}
            label={t("phone")}
            type="text"
            autoComplete="off"
            value={phone}
            onChange={setPhone}
          />
          <TextField
            disabled={userId}
            label={t("external_code")}
            type="text"
            autoComplete="off"
            value={externalCode}
            onChange={setExternalCode}
          />
          <Checkbox
            label={t("disabled")}
            checked={isDisabled}
            onChange={handleIsDisabledChange}
          />
          {filesForm}
          {submitNode}
        </FormLayout>
      </Form>
    </div>
  );
}

export default UserForm;
