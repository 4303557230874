import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "constants.js";
import { fetchProjectById } from "services/project.service";

export const fetchProject = createAsyncThunk(
  "project/fetchProject",
  async ({ companyId, projectId }) => {
    const url = `${API_URL}/${companyId}/projects/${projectId}`;
    const response = await axios.get(url);
    return response.data;
  }
);

const initialState = {
  isLoading: true,
  isError: false,
  errMsg: "",
  companyName: "",
  companyCode: "",
  projectDetails: null,
};

const projectContainerSlice = createSlice({
  name: "projectContainer",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setDestroy() {
      return initialState;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setProjectContainer(state, action) {
      state.projectDetails = action.payload;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projectDetails = action.payload;
      })
      .addCase(fetchProject.rejected, (state, action) => {
        state.isLoading = false;
        state.errMsg = action.error.message;
      });
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = projectContainerSlice;

// Extract and export each action creator by name
export const { setDestroy, setLoading, setError, setProjectContainer } =
  actions;
// Export the reducer, either as a default or named export
export default reducer;

export const fetchProjectDetails =
  ({ companyId, projectId }) =>
  async (dispatch) => {
    dispatch(setLoading());
    try {
      const data = await fetchProjectById(companyId, projectId);
      dispatch(setProjectContainer(data));
    } catch (e) {
      dispatch(setError("not_saved"));
      return false;
    }
  };
