import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { CalendarIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import {
  DatePicker as ShopifyDatePicker,
  BlockStack,
  Box,
  Popover,
  TextField,
  Icon,
  Card,
} from "@shopify/polaris";

function DatePicker({ selected, onChange, title = "", isRequired=false }) {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(
    selected ? new Date(selected) : null
  );
  const [{ month, year }, setDate] = useState({
    month: selectedDate ? selectedDate.getMonth() : new Date().getMonth(),
    year: selectedDate ? selectedDate.getFullYear() : new Date().getFullYear(),
  });
  const [inputValue, setInputValue] = useState(
    selectedDate ? selectedDate.toISOString().slice(0, 10) : "-"
  );
  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickerRef]);

  function formatDateString(value) {
    // Remove all non-digit characters
    const cleanValue = value.replace(/\D/g, "");

    let year = cleanValue.slice(0, 4);
    let month = cleanValue.slice(4, 6);
    let day = cleanValue.slice(6, 8);

    // Limit month between 1 and 12
    if (month && parseInt(month, 10) > 12) {
      month = "12";
    }

    // Limit day based on month
    if (day) {
      const maxDaysInMonth = new Date(
        parseInt(year, 10),
        parseInt(month, 10),
        0
      ).getDate();
      if (parseInt(day, 10) > maxDaysInMonth) {
        day = maxDaysInMonth.toString();
      }
    }

    let formattedValue = year;
    if (month) {
      formattedValue += `-${month}`;
    }
    if (day) {
      formattedValue += `-${day}`;
    }

    return formattedValue;
  }

  function handleInputValueChange(value) {
    // console.log("handleInputValueChange", value);
    const formattedValue = formatDateString(value);
    setInputValue(formattedValue);

    // Complete date length: YYYY-MM-DD
    if (formattedValue.length === 10) {
      const [year, month, day] = formattedValue.split("-").map(Number);
      const newDate = new Date(Date.UTC(year, month - 1, day));
      if (!isNaN(newDate.getTime())) {
        setSelectedDate(newDate);
        setDate({
          month: newDate.getMonth(),
          year: newDate.getFullYear(),
        });
        onChange(newDate);
      }
    } else if (value === "") {
      setInputValue("-");
      setSelectedDate(null);
      onChange(null);
    }
  }

  function handleOnClose({ relatedTarget }) {
    setVisible(false);
  }
  function handleMonthChange(month, year) {
    setDate({ month, year });
  }
  function handleDateSelection({ end: newSelectedDate }) {
    const newDate = new Date(
      Date.UTC(
        newSelectedDate.getFullYear(),
        newSelectedDate.getMonth(),
        newSelectedDate.getDate()
      )
    );
    setSelectedDate(newSelectedDate);
    setInputValue(newDate.toISOString().slice(0, 10));
    setVisible(false);
    onChange(newDate);
  }
  useEffect(() => {
    if (selectedDate) {
      setDate({
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear(),
      });
    }
    // check error
    errorHandler(selectedDate)
  }, [isRequired, selectedDate]);

  const [error, setError] = useState("");
  const errorHandler = (val) => {
    if (isRequired && !val) {
      setError(t("field_is_required"));
    } else {
      setError("");
    }
  }
 
  return (
    <BlockStack inlineAlign="center" gap="0">
      <Box minWidth="275px">
        <Popover
          active={visible}
          autofocusTarget="none"
          preferredAlignment="left"
          fullWidth
          preferInputActivator={false}
          preferredPosition="below"
          preventCloseOnChildOverlayClick
          onClose={handleOnClose}
          activator={
            <TextField
              role="combobox"
              label={title || "Start date"}
              prefix={<Icon source={CalendarIcon} />}
              value={inputValue}
              onFocus={() => setVisible(true)}
              onChange={handleInputValueChange}
              autoComplete="off"
              placeholder="YYYY-MM-DD"
              requiredIndicator={isRequired ? true : false}
              error={error}
            />
          }
        >
          <div ref={datePickerRef}>
            <Card>
              <ShopifyDatePicker
                month={month}
                year={year}
                selected={selectedDate}
                onMonthChange={handleMonthChange}
                onChange={handleDateSelection}
                weekStartsOn={1}
              />
            </Card>
          </div>
        </Popover>
      </Box>
    </BlockStack>
  );
}

export default DatePicker;

DatePicker.propTypes = {
  selected: PropTypes.string,
  // disableDatesBefore: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
