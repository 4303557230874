import React, { useEffect } from "react";
// import _ from 'lodash';
import { useSelector, useDispatch } from "react-redux";
import {
  Page,
  IndexTable,
  Text,
  Layout,
  Icon,
  Card,
  Filters,
  ChoiceList,
} from "@shopify/polaris";
import { CheckIcon, ClockIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { fetchUsers, setFilterValue } from "./users.slice";
import { setModalxComponent } from "components/modalx/modalx.slice";

function Users() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const resourceName = {
    singular: "user",
    plural: "users",
  };
  const { isUserMaster } = useSelector((state) => state.companySelectReducer);
  const {
    usersReducer: { usersCollection, isLoading, filtersValue },
    // userReducer: { userEntity },
  } = useSelector((state) => state);

  const handleCreateUser = () => {
    dispatch(setModalxComponent("UserForm"));
  };

  const disambiguateLabel = (key, value) => {
    switch (key) {
      case "textSearch":
        return `${t("search")}: ${t(value)}`;
      default:
        return value;
    }
  };

  useEffect(() => {
    dispatch(fetchUsers(companyId, filtersValue));
  }, [dispatch, companyId, filtersValue]);

  const rowMarkup = usersCollection.map(
    ({ id, email, name, phone, isDisabled }, index) => (
      <IndexTable.Row
        id={id}
        key={id}
        position={index}
        // selected={_.get(userEntity, 'id') === id}
        status={isDisabled ? "subdued" : ""}
      >
        <div style={{ padding: "1.2rem 1.6rem" }}>
          <div>
            <div style={{ display: "flex" }}>
              <div>
                {!isDisabled ? (
                  <Icon source={CheckIcon} color="success" />
                ) : (
                  <Icon source={ClockIcon} color="subdued" />
                )}
              </div>
              <div style={{ paddingLeft: "10px", flex: "1" }}>
                {isUserMaster ? (
                  <Link to={`/app/${companyId}/users/${id}`}>
                    <Text as="span" fontWeight="semibold">
                      {name || email}
                    </Text>
                  </Link>
                ) : (
                  <Text as="span" fontWeight="semibold">
                    {name || email}
                  </Text>
                )}
              </div>
              <div style={{ whiteSpace: "nowrap" }}>{phone}</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "10px",
            }}
          >
            <Text>{t("user_position")}</Text>
            <div>{email}</div>
          </div>
        </div>
      </IndexTable.Row>
    )
  );
  const handleFilterChange = (key, value) => {
    dispatch(setFilterValue({ key, value }));
  };
  const handleFilterStatusChange = (value) => {
    dispatch(setFilterValue({ key: "isDisabled", value }));
  };
  const handleQueryChange = (value) => {
    dispatch(setFilterValue({ key: "textSearch", value }));
  };
  const handleQueryValueRemove = () => {
    dispatch(setFilterValue({ key: "textSearch", value: "" }));
  };
  const handleClearAll = () => {};
  const appliedFilters = [];
  Object.keys(filtersValue).forEach((key) => {
    if (filtersValue[key] && filtersValue[key].length > 0) {
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, filtersValue[key]),
        onRemove: () => handleFilterChange(key, null),
      });
    }
  });

  const filters = [
    {
      key: "isDisabled",
      label: t("user_status"),
      filter: (
        <ChoiceList
          allowMultiple
          title={t("user_status")}
          choices={[
            {
              label: t("is_disabled"),
              value: "is_disabled",
            },
            {
              label: t("not_disabled"),
              value: "not_disabled",
            },
          ]}
          selected={filtersValue.isDisabled || []}
          onChange={handleFilterStatusChange}
        />
      ),
      shortcut: true,
    },
  ];

  return (
    <Layout>
      <Layout.Section>
        <Page
          fullWidth
          primaryAction={
            isUserMaster
              ? { content: t("create_user"), onAction: handleCreateUser }
              : null
          }
          title={t("users")}
        >
          <Card>
            <div style={{ padding: "16px", display: "flex" }}>
              <div style={{ flex: 1 }}>
                <Filters
                  queryValue={filtersValue.textSearch}
                  filters={filters}
                  appliedFilters={appliedFilters}
                  onQueryChange={handleQueryChange}
                  onQueryClear={handleQueryValueRemove}
                  onClearAll={handleClearAll}
                />
              </div>
            </div>
            <IndexTable
              resourceName={resourceName}
              itemCount={usersCollection.length}
              loading={isLoading}
              condensed
              selectable={false}
              headings={[
                { title: t("name") },
                { title: t("phone") },
                { title: t("Email") },
                { title: t("isDisabled") },
              ]}
            >
              {rowMarkup}
            </IndexTable>
          </Card>
        </Page>
      </Layout.Section>
    </Layout>
  );
}

export default Users;
