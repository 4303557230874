import { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import _ from "lodash";
import { Select } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { API_URL } from "../../constants";

function FileCategorySelect({
  value,
  onChange,
  isRequired,
  subject = "customer",
}) {
  const { t } = useTranslation();
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams();
  const [error, setError] = useState("");

  const handleChange = (selectedValue) => {
    if (isRequired && !selectedValue) {
      setError(t("field_is_required"));
    } else {
      setError("");
    }

    onChange(selectedValue);
  };

  function sortCategories(data) {
    // Helper function to find children recursively with customized prefix
    const findChildren = (parentId, depth) => {
      return data
        .filter((item) => item.parentCategory === parentId)
        .map((item) => {
          return {
            ...item,
            title: `${"﹘".repeat(depth)}⋗ ${item.title}`,
            children: findChildren(item.id, depth + 1),
          };
        });
    };

    // Get all parent categories (where parentCategory is null)
    const parents = data.filter((item) => item.parentCategory === null);

    // Build the sorted structure
    let sortedData = [];

    parents.forEach((parent) => {
      sortedData.push(parent);
      sortedData = sortedData.concat(findChildren(parent.id, 1));
    });

    // Flatten the result
    const flattenData = (items) => {
      return items.reduce((acc, item) => {
        acc.push(item);
        if (item.children) {
          acc = acc.concat(flattenData(item.children));
        }
        return acc;
      }, []);
    };

    return flattenData(sortedData);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const collection = await fetch(companyId, subject);
        // Sort and print the result
        const sortedCategories = sortCategories(collection);
        const dataCollection = _.map(sortedCategories, (row) => ({
          label: row.title,
          value: row.id,
        }));
        dataCollection.unshift({ label: " - ", value: "" });
        setCollection(dataCollection);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [companyId, subject]);

  return (
    <div className="file-category-select">
      <Select
        label={t("file_category_user")}
        options={collection}
        onChange={handleChange}
        value={value}
        disabled={isLoading}
        requiredIndicator={isRequired ? true : false}
        error={error}
      />
    </div>
  );
}

export default FileCategorySelect;

FileCategorySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
  subject: PropTypes.string,
  isRequired: PropTypes.bool,
};

FileCategorySelect.defaultProps = {
  isRequired: false,
};

function fetch(companyId, subject) {
  const url = `${API_URL}/${companyId}/files/categories-fetch/${subject}`;
  return axios.get(url).then((response) => response.data);
}
