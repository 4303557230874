import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "configureStore";
import { FormLayout, TextField, Form, Button, Banner } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchSeriesList } from "containers/files-series/files-series-container.slice";
import {
  createSeries,
  updateSeries,
  fetchSeriesById,
} from "services/series.service";

interface seriesFormProps {
  // onSubmit?: (result: any) => void;
  id?: string;
}

const SeriesForm: React.FC<seriesFormProps> = ({ id }) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFormSubmit = async () => {
    const formData = {
      id,
      title,
      description,
    };
    const action = id ? updateSeries : createSeries;
    try {
      setIsLoading(true);
      await action(formData, companyId);
      if (companyId) dispatch(fetchSeriesList(companyId));
      setSuccessMessage("ok");
      setIsLoading(false);
    } catch (e) {
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  const fetchData = useCallback(
    async (id: string) => {
      const { title, description } = await fetchSeriesById(companyId!, id);
      setTitle(title);
      setDescription(description);
    },
    [companyId]
  );

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id, fetchData]);

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div>
              <Banner tone="critical">{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div>
              <Banner tone="info">{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <TextField
          label={t("series_label")}
          id="label"
          name="label"
          value={title}
          onChange={(value) => setTitle(value)}
          autoComplete="off"
        />
        <TextField
          label={t("description")}
          id="description"
          name="description"
          multiline={4}
          value={description}
          onChange={setDescription}
          autoComplete="off"
        />
        <Button
          onClick={handleFormSubmit}
          disabled={!title}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
};

export default SeriesForm;
