import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  InlineStack,
  Select,
  Checkbox,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { submit } from "./file-form.slice";
import { fetchById } from "services/upload-file.service";
import FileCategorySelect from "components/file-category-select/file-category-select";
import Loading from "components/loading/Loading";
import CustomerSelect from "components/customer-select/CustomerSelect";
import { post } from "features/customers/customers.service";
import _ from "lodash";
import DatePickerx from "features/datepicker/date-picker";
import "./style.scss";
import UserSelect from "features/user-select/user-select";
import SeriesSelect from "features/series-selector/series-select";
import FilesSelect from "components/files-types-select/files-types-select";

function FileForm({
  fileId,
  onChange,
  companyId,
  projectId,
  customerId,
  isCustomerFormRequired,
  categoryId,
  parentFileId,
  categoryType,
  isCategoryRequired= true
}) {
  const dispatch = useDispatch();
  const [id, setId] = useState();
  const [name, setName] = useState("");
  const [category, setCategory] = useState(null);
  const [dateTo, setDateTo] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectId, setSubjectId] = useState(null);
  const [customer, setCustomer] = useState();
  const [toggleBox, setToggelBox] = useState(false);
  const [relatedTo, setRelatedTo] = useState();
  const [date, setDate] = useState();
  const [series, setSeries] = useState("");
  const [assignedUser, setAssignedUser] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const [externalNumber, setExternalNumber] = useState("");
  const [isCustomerOptional, setIsCustomerOptional] = useState(false);
  const [formIsValid, setFormIsValid] = useState(true);
  const [fileType, setFileType] = useState("");
  const [fileTypeContext, setFileTypeContext] = useState("");

  const { t } = useTranslation();
  const { isLoading } = useSelector((state) => state.fileFormReducer);

  // Error state to store validation errors
  const [errors, setErrors] = useState({
    fileType: "",
    customFields: {},
    category: "",
    assignedUser: "",
    customer:""
  });

  useEffect(() => {
    const validationErrors = { fileType: "", customFields: {}, category: "", assignedUser: "" };
    let valid = true;

    // Validate file name
    if (!fileType) {
      validationErrors.fileType = t("file_type_is_required");
      valid = false;
    }

    // Validate required custom fields
    customFields.forEach((field) => {
      if (field.isRequired && !field.value && field.fieldType != "checkbox") {
        validationErrors.customFields[field.id] = t("field_is_required");
        valid = false;
      }
    });

    // Validate category
    if (!category && isCategoryRequired && !relatedTo) {
      validationErrors.category = t("category_is_required");
      valid = false;
    }

    // Validate assigned user
    if (!assignedUser) {
      validationErrors.assignedUser = t("user_is_required");
      valid = false;
    }

    // customer required check
    if (isCustomerFormRequired && !isCustomerOptional && !customer){
      validationErrors.customer = t("customer_is_required");
      valid = false;
    }

    setErrors(validationErrors);
    setFormIsValid(valid);
  }, [fileType, customFields, category, assignedUser, customer]);

  const handleFormSubmit = async () => {
    // convert customField data array to an object
    const customizedCustomFieldData = customFields.reduce((acc, field) => {
      acc[field.id] = field["value"];
      return acc;
    }, {});

    const formData = {
      id,
      name,
      category,
      dateTo,
      subject,
      subjectId: isCustomerFormRequired ? customer : subjectId,
      customerId: customerId ? customerId : customer?.id,
      companyId,
      projectId,
      isCustomerFormRequired,
      relatedTo: parentFileId ? parentFileId : relatedTo,
      date,
      series,
      assignedUser,
      customFields: customizedCustomFieldData,
      externalNumber,
      fileType,
      fileTypeContext
    };

    await dispatch(submit(formData));
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const {
          name,
          category,
          dateTo,
          subject,
          subjectId,
          relatedTo,
          date,
          assignedUser,
          customFields,
          series,
          externalNumber,
          customer,
          fileType,
          fileTypeContext,
        } = await fetchById(fileId, companyId);
        setName(name);
        setCategory(category);
        setDateTo(dateTo);
        setSubject(subject);
        setSubjectId(subjectId);
        category ? setCategory(category?.id) : setCategory(categoryId);
        setRelatedTo(relatedTo);
        setDate(date);
        setSeries(series?.id);
        setExternalNumber(externalNumber);
        setAssignedUser(assignedUser?.id);
        setCustomFields(customFields);
        setCustomer(customer);
        setFileType(fileType);
        setFileTypeContext(fileTypeContext);
        if (isCustomerFormRequired) {
          setCustomer(subjectId);
          setSubject("customer");
          setSubjectId(customer);
        }
      } catch (e) {
        // console.error(e);
      }
    }
    if (fileId) {
      setId(fileId);
      fetchData();
    }
  }, [fileId, companyId, categoryId, isCustomerFormRequired]);

  useEffect(() => {
    if (categoryType === "internal") {
      setIsCustomerOptional(true);
    }
  }, [categoryType]);

  if (isLoading || !subject) {
    return <Loading />;
  }

  const handleSwitchIpBox = () => {
    setToggelBox(!toggleBox);
    setCustomer();
  };

  const handleCreateCustomer = async () => {
    if (toggleBox) {
      const formData = {
        name: customer,
        companyId,
        address: "",
        bankIban: "",
        bankName: "",
        companyCode: "",
        contactPerson: "",
        customerId: "",
        email: "",
        fine: "",
        maxAllowedDebt: "",
        paymentDelay: "",
        phone: "",
        prepaymentPercentage: "",
        vatCode: "",
      };
      const result = await post(companyId, formData);
      setToggelBox(!toggleBox);
      setCustomer(_.get(result, "createdCustomer.id"));
    }
  };

  const handleDueDateChange = (value) => setDateTo(value);

  const handleCustomField = (value, id) => {
    setCustomFields((prevFields) =>
      prevFields.map((field) => (field.id === id ? { ...field, value } : field))
    );
  };

  return (
    <div className="user-form">
      <Form className="user-form_form" onSubmit={handleFormSubmit}>
        <FormLayout>
          <FilesSelect 
            isRequired={true}
            onChange={setFileType}
            value={fileType}
          />
          <TextField
            disabled={isLoading}
            label={t("file_context")}
            type="text"
            autoComplete="off"
            value={fileTypeContext}
            onChange={setFileTypeContext}
            requiredIndicator={true}
            // error={errors.context}
            labelHidden
            placeholder={t("enter_file_context")}
          />
          {!relatedTo && isCategoryRequired &&
            <FileCategorySelect
              onChange={setCategory}
              value={category}
              subject={subject}
              isRequired={true}
            />
          }
          {isCustomerFormRequired && (
            <>
              {!toggleBox && (
                <>
                  <span onClick={handleSwitchIpBox}>
                    <Button>{t("create_customer")}</Button>
                  </span>
                  <CustomerSelect value={customer} onChange={setCustomer} isRequired={isCustomerOptional ? false : true} allowMultiple={true}/>
                </>
              )}
              {toggleBox && (
                <>
                  <span onClick={handleSwitchIpBox}>
                    <Button>{t("select_existing_customer")}</Button>
                  </span>
                  <TextField
                    label={t("customer_name")}
                    value={customer}
                    onChange={setCustomer}
                    autoComplete="off"
                    requiredIndicator={isCustomerOptional ? false : true}
                    error={isCustomerFormRequired && !customer && !isCustomerOptional ?  t("field_is_required") : ''}
                  />
                  <div className="marginTop">
                    <Button
                      loading={isLoading}
                      disabled={!customer}
                      onClick={handleCreateCustomer}
                    >
                      {t("save_customer")}
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
          <SeriesSelect onChange={setSeries} value={series} />
          <UserSelect 
            onChange={setAssignedUser} 
            value={assignedUser} 
            isRequired={true} 
            label={"Select assigned user"}
            />
          <TextField
            label={t("externalNumber")}
            value={externalNumber}
            onChange={setExternalNumber}
            autoComplete="off"
          />
          <InlineStack align="space-between">
            <DatePickerx
              name="date"
              title={t("document_date")}
              onChange={setDate}
              selected={date}
            />
            <DatePickerx
              name="dueDate"
              title={t("dateTo")}
              onChange={handleDueDateChange}
              selected={dateTo}
            />
          </InlineStack>
          {customFields && (
            <div className="column-2">
              {customFields?.map((field) => {
                if (
                  !field.fieldType ||
                  !field.isVisibleInList ||
                  field.fieldType === "checkbox"
                )
                  return null;
                return (
                  <div style={{ width: "48%" }}>
                    {field.fieldType === "select" && (
                      <Select
                        label={field.label}
                        options={[
                          { label: "-", value: "" },
                          ...field.fieldOptions,
                        ]}
                        onChange={(value) => handleCustomField(value, field.id)}
                        value={field.value}
                        error={errors.customFields[field.id]}
                        requiredIndicator={field.isRequired}
                      />
                    )}
                    {field.fieldType === "date" && (
                      <DatePickerx
                        name="date"
                        title={field.label}
                        onChange={(value) => handleCustomField(value, field.id)}
                        selected={field.value}
                        isRequired={field.isRequired ? true : false}
                      />
                    )}
                    {(field.fieldType === "text" ||
                      field.fieldType === "number") && (
                      <TextField
                        type={field.fieldType}
                        key={field.id}
                        label={field.label}
                        value={field.value}
                        onChange={(value) => handleCustomField(value, field.id)}
                        autoComplete="off"
                        requiredIndicator={field.isRequired}
                        error={errors.customFields[field.id]}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {customFields && (
            <div className="column-2">
              {customFields.map((field) => {
                if (
                  !field.fieldType ||
                  field.fieldType !== "checkbox" ||
                  !field.isVisibleInList
                )
                  return null;
                return (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "20px",
                    }}
                    key={field.id}
                  >
                  <Checkbox
                    key={field.label}
                    label={field.label}
                    checked={field.value == 'true' ? true : false}
                    onChange={() =>{
                      const value = field.value == 'true' ? 'false' : 'true';
                      handleCustomField(value, field.id)
                    }}
                  />
                  </div>
                );
              })}
            </div>
          )}

          <Button
            loading={isLoading}
            disabled={!formIsValid || toggleBox}
            submit
          >
            {t("save")}
          </Button>
        </FormLayout>
      </Form>
    </div>
  );
}

export default FileForm;
