import { useEffect, useState, useCallback } from "react";
import { useAppDispatch } from "hooks";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Checkbox,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchProjectBoard } from "containers/project-board/project-board-container.slice";
import { createProject, updateProject } from "./project-form.service";
import { fetchProjectById } from "services/project.service";
import ProjectColumnSelect from "components/project-column-select/project-column-select";
import CustomerSelect from "components/customer-select/CustomerSelect";
import UserSelect from "features/user-select/user-select";
import ProductsSelect from "components/products-select/ProductsSelect";
import Loading from "components/loading/Loading";
import { fetchProjectDetails } from "containers/project-view-container/project-container.slice";

function ProjectForm({ projectBoardId, projectBoardColumnId, id }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [projectBoardColumn, setProjectBoardColumn] =
    useState(projectBoardColumnId);
  const [user, setUser] = useState();
  const [customer, setCustomer] = useState();
  const [order, setOrder] = useState();
  const [products, setProducts] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [errMsg, setErrMsg] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isImportant, setIsImportant] = useState(false);

  const handleChange = useCallback(
    (newChecked) => setIsImportant(newChecked),
    []
  );

  const handleFormSubmit = async () => {
    const formData = {
      id,
      projectBoardColumn,
      name,
      description,
      user,
      customer,
      order,
      products,
      isImportant,
    };
    const action = id ? updateProject : createProject;
    try {
      setIsLoading(true);
      await action(formData, companyId);
      dispatch(fetchProjectBoard(companyId, projectBoardId));
      if (id) dispatch(fetchProjectDetails({ companyId, projectId: id }));
      setSuccessMessage("ok");
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  const fetchData = useCallback(
    async (id) => {
      setIsLoading(true);
      const data = await fetchProjectById(companyId, id);
      setName(data.name);
      setDescription(data.description);
      setProjectBoardColumn(data.projectBoardColumn.id);
      setUser(data.user.id);
      setCustomer(data.customer.id);
      setOrder(data.order);
      setProducts(data.products);
      setIsImportant(data.isImportant);
      setIsLoading(false);
    },
    [companyId]
  );

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id, fetchData]);

  if (isLoading && !name) {
    return <Loading />;
  }

  return (
    <Form className="project-board-column-form">
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div className="">
              <Banner status="critical">{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div className="">
              <Banner status="info">{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <ProjectColumnSelect
          value={projectBoardColumn}
          onChange={setProjectBoardColumn}
          projectBoardId={projectBoardId}
          isRequired={true}
        />
        <CustomerSelect value={customer} onChange={setCustomer} />
        <UserSelect onChange={setUser} value={user} label={"select_user"}/>
        <TextField
          label={t("project-name")}
          id="name"
          name="name"
          value={name}
          onChange={setName}
        />
        <TextField
          label={t("project-description")}
          id="description"
          name="description"
          multiline={4}
          value={description}
          onChange={setDescription}
        />
        <TextField
          label={t("project-order")}
          id="order"
          name="order"
          value={order}
          onChange={setOrder}
        />
        <ProductsSelect initialProducts={products} onChange={setProducts} />
        <Checkbox
          label={t("important")}
          checked={isImportant}
          onChange={handleChange}
        />
        <Button
          primary
          onClick={handleFormSubmit}
          disabled={!name || !projectBoardId}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
}

export default ProjectForm;
