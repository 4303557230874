import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "configureStore";
import {
  Layout,
  Page,
  Card,
  Text,
  BlockStack,
  Spinner,
  TextContainer,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchCompanyDetails,
  setDestroy,
} from "containers/settings/settings-container.slice";
import UserList from "features/user-list/user-list";

function CompanyAbout() {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();

  const loadCompanyData = useCallback(() => {
    if (companyId) {
      dispatch(fetchCompanyDetails({ companyId }));
    }
  }, [companyId, dispatch]);

  const { companyEntity, isLoading } = useSelector(
    (state: RootState) => state.companyContainerReducer
  );

  useEffect(() => {
    loadCompanyData();
    return () => {
      setDestroy();
    };
  }, [loadCompanyData]);

  if (!companyEntity) {
    return null;
  }

  return (
    <Page
      fullWidth
      title={companyEntity.name}
      titleMetadata={isLoading && <Spinner size="small" />}
    >
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            {/* <Card roundedAbove="sm"> */}
              <UserList/>
            {/* </Card> */}
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="500">
          <Card roundedAbove="sm">
              <TextContainer>
                <Text as="p" variant="bodyMd">
                  <strong>{t("title")}:</strong>{" "}
                  {companyEntity?.name}
                </Text>
                {companyEntity.legalName ?
                  <Text as="p" variant="bodyMd">
                    <strong>{t("legal_name")}:</strong> {companyEntity.legalName}
                  </Text>
                  :
                  <Text as="p" variant="bodyMd">
                    {t("no_legal_name_provided")}
                  </Text>
                }
                {companyEntity.vat ?
                  <Text as="p" variant="bodyMd">
                    <strong>{t("company_vat")}:</strong> {companyEntity.vat}
                  </Text>
                  :
                  <Text as="p" variant="bodyMd">
                    {t("no_vat_provided")}
                  </Text>
                }
                {companyEntity.code ?
                  <Text as="p" variant="bodyMd">
                    <strong>{t("company_code")}:</strong> {companyEntity.code}
                  </Text>
                  :
                  <Text as="p" variant="bodyMd">
                    {t("no_code_provided")}
                  </Text>
                }
                {companyEntity.address ?
                  <Text as="p" variant="bodyMd">
                    <strong>{t("address")}:</strong> {companyEntity.address}
                  </Text>
                  :
                  <Text as="p" variant="bodyMd">
                    {t("no_address_provided")}
                  </Text>
                }
                {companyEntity.description ? <Text as="p" variant="bodyMd">
                  <strong>{t("description")}:</strong> {companyEntity.description}
                </Text>
                  :
                  <Text as="p" variant="bodyMd">
                    {t("no_description_provided")}
                  </Text>
                }
              </TextContainer>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default CompanyAbout;
