import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  Page,
  ResourceList,
  ResourceItem,
  Card,
  Layout,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { fetchProjectBoards } from "./project-boards.slice";
import { setModalxComponent } from "components/modalx/modalx.slice";
import Loading from "components/loading/Loading";

function ProjectBoards() {
  const dispatch = useAppDispatch();
  const { companyId } = useParams();
  const { t } = useTranslation();

  const {
    projectBoardsReducer: { projectBoardsCollection, isLoading },
  } = useAppSelector((state) => state);

  useEffect(() => {
    dispatch(fetchProjectBoards(companyId));
  }, [dispatch, companyId]);

  const resourceName = {
    singular: t("project-boards"),
    plural: t("project-boards"),
  };

  const handleCreateBoard = () => {
    dispatch(setModalxComponent("ProjectBoardForm"));
  };

  return (
    <Layout>
      <Layout.Section>
        <Page
          fullWidth
          title={t("project-boards")}
          primaryAction={{
            content: t("project-boards-create"),
            onAction: handleCreateBoard,
          }}
        >
          {isLoading && <Loading />}
          {!isLoading && (
            <Card>
              <ResourceList
                resourceName={resourceName}
                items={projectBoardsCollection}
                renderItem={(item) => {
                  const { id, name } = item;

                  return (
                    <ResourceItem id={id} url={id}>
                      <div className="layout-row justify-space">
                        <div>{name}</div>
                      </div>
                    </ResourceItem>
                  );
                }}
              />
            </Card>
          )}
        </Page>
      </Layout.Section>
    </Layout>
  );
}

export default ProjectBoards;
