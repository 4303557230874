import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Page,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { submit, setError, destroy } from "./vat-form.slice";
import { fetchById } from "../vats/vats.service";
import "./style.scss";

function VatForm({ onSubmit }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { vatId } = useParams();
  const { isLoading, errMsg, successMessage } = useSelector(
    (state) => state.vatFormReducer
  );
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [percent, setPercent] = useState("");
  const [percentError, setPercentError] = useState("");

  const handleFormSubmit = async () => {
    const formData = {
      vatId,
      title,
      percent,
    };

    const id = await dispatch(submit(formData));
    onSubmit(id);
  };

  const handleTitleChange = (value) => {
    setTitle(value);

    if (!value) {
      setTitleError(t("field_is_required"));
    } else {
      setTitleError("");
    }
  };

  const handlePercentChange = (value) => {
    setPercent(value);

    if (!value) {
      setPercentError(t("field_is_required"));
    } else {
      setPercentError("");
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { vatEntity } = await fetchById(vatId);
        setTitle(vatEntity.title);
        setPercent(vatEntity.percent);
      } catch (e) {
        dispatch(setError("error_getting_data"));
      }
    }

    if (vatId) {
      fetchData();
    }

    return () => {
      dispatch(destroy());
    };
  }, [vatId, dispatch]);

  return (
    <Page fullWidth title={t("vat")}>
      <div className="vat-form">
        <Form className="vat-form_form">
          <FormLayout>
            <div className="form-warnings">
              {errMsg && (
                <div className="">
                  <Banner status="critical">{t(errMsg)}</Banner>
                </div>
              )}
              {successMessage && (
                <div className="">
                  <Banner status="info">{t(successMessage)}</Banner>
                </div>
              )}
            </div>
            <TextField
              label={t("title")}
              id="title"
              name="title"
              value={title}
              error={t(titleError)}
              onChange={handleTitleChange}
              requiredIndicator
            />
            <TextField
              label={t("percent")}
              id="percent"
              name="percent"
              value={percent}
              error={t(percentError)}
              onChange={handlePercentChange}
              requiredIndicator
            />
            <Button
              primary
              onClick={handleFormSubmit}
              disabled={!title || !percent}
              loading={isLoading}
            >
              {t("save")}
            </Button>
          </FormLayout>
        </Form>
      </div>
    </Page>
  );
}

export default VatForm;

VatForm.propTypes = {
  onSubmit: PropTypes.func,
};

VatForm.defaultProps = {
  onSubmit: () => {},
};
