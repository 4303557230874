import React from "react";

interface PrettyDateProps {
  dateString: string;
  locale?: string; // Optional prop to allow custom locale
  showTime?: boolean; // Optional prop to show time
}

const PrettyDate: React.FC<PrettyDateProps> = ({
  dateString,
  locale = "lt-LT",
  showTime = false,
}) => {
  try {
    // Convert the date string to a Date object
    const date = new Date(dateString);

    // Define date format options
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit", // Use '2-digit' to ensure the month is always two digits
      day: "2-digit", // Use '2-digit' to ensure the day is always two digits
    };

    // Define time format options if showTime is true
    if (showTime) {
      dateOptions.hour = "2-digit";
      dateOptions.minute = "2-digit";
      dateOptions.second = "2-digit";
    }

    // Use Intl.DateTimeFormat to format the date
    const formatter = new Intl.DateTimeFormat(locale, dateOptions);

    // Extract the parts of the date and manually format it to "YYYY-MM-DD"
    const parts = formatter.formatToParts(date);
    const year = parts.find((part) => part.type === "year")?.value;
    const month = parts.find((part) => part.type === "month")?.value;
    const day = parts.find((part) => part.type === "day")?.value;
    const hour = parts.find((part) => part.type === "hour")?.value;
    const minute = parts.find((part) => part.type === "minute")?.value;
    const second = parts.find((part) => part.type === "second")?.value;

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = showTime ? ` ${hour}:${minute}:${second}` : "";

    return (
      <span>
        {formattedDate}
        {formattedTime}
      </span>
    );
  } catch (e) {
    console.warn(e);
    return <span>-</span>;
  }
};

export default PrettyDate;
