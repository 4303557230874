import axios from "axios";
import { API_URL } from "constants";

export const createContact = async (formData, customerId, companyId) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}/contacts`;
  return axios.post(url, formData).then((response) => response.data);
};

export const updateContact = async (formData, customerId, companyId) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}/contacts/${formData.id}`;
  return axios.patch(url, formData).then((response) => response.data);
};

export const fetchContactById = async (companyId, customerId, id) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}/contacts/${id}`;
  return axios.get(url).then((response) => response.data);
};

export const deleteContact = async (contactId, customerId, companyId) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}/contacts/${contactId}`;
  return axios.delete(url).then((response) => response.data);
};
