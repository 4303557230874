import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSeries } from "services/series.service";
import { seriesEntity } from "types/Series";

// Define the types for your initial state
interface seriesContainerState {
  isLoading: boolean;
  isError: boolean;
  errMsg: string;
  collection: seriesEntity[];
}

const initialState: seriesContainerState = {
  isLoading: true,
  isError: false,
  errMsg: "",
  collection: [],
};

const seriesContainerSlice = createSlice({
  name: "series",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setDestroy() {
      return initialState;
    },
    setLoading(state: seriesContainerState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(
      state: seriesContainerState,
      action: PayloadAction<{ error: string }>
    ) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setData(state: seriesContainerState, action: PayloadAction<any>) {
      state.collection = action.payload;
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = seriesContainerSlice;

export const { setDestroy, setLoading, setError, setData } = actions;
export default reducer;

export const fetchSeriesList = (companyId: string) => async (dispatch: any) => {
  dispatch(setLoading(true));
  try {
    const data = await fetchSeries(companyId);
    dispatch(setData(data));
  } catch (e) {
    const error = "not_fetch";
    dispatch(setError({ error }));
  } finally {
    dispatch(setLoading(false));
  }
};
