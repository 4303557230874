import axios from "axios";
import { API_URL } from "constants";


export const updateCompany = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}`;
  return axios.patch(url, formData).then((response) => response.data);
};

export const fetchById = async (companyId) => {
  const url = `${API_URL}/${companyId}`;
  return axios.get(url).then((response) => response.data);
};

export const updateMicrosoftSettings = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/microsoft-settings`;
  return axios.patch(url, formData).then((response) => response.data);
};

export const updateFinvaldaSettings = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/finvalda-settings`;
  return axios.patch(url, formData).then((response) => response.data);
};
