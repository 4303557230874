import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../constants";

const initialState = {
  isLoading: true,
  isError: false,
  errMsg: "",
  totalDebt: 0,
  totalOverdueDebt: 0,
  debtInvoices: [],
};

const debtInvoicesContainerSlice = createSlice({
  name: "debtInvoices",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setDestroy() {
      return initialState;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setData(state, action) {
      state.totalDebt = action.payload.totalDebt;
      state.totalOverdueDebt = action.payload.totalOverdueDebt;
      state.debtInvoices = action.payload.debtInvoices;
      state.isLoading = false;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = debtInvoicesContainerSlice;

// Extract and export each action creator by name
export const { setDestroy, setLoading, setError, setData } = actions;
// Export the reducer, either as a default or named export
export default reducer;

export const fethDebtInvoices = (company) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const data = await fetchData(company);
    dispatch(setData(data));
  } catch (e) {
    dispatch(setError("not_saved"));
    return false;
  }
};

const fetchData = (company) => {
  const url = `${API_URL}/companies/${company}/fetch-debt`;
  return axios.get(url).then((response) => response.data);
};
