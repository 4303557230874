import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Checkbox,
  Page,
  Form,
  Card,
  InlineGrid,
  Text,
  InlineStack,
  Select,
  Bleed,
  Box,
  BlockStack,
  FormLayout,
  Button,
  TextField,
  EmptyState,
  DropZone,
  ResourceList,
  ResourceItem,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL } from "constants";
import "./customer-form.scss";
import Loading from "components/loading/Loading";
import { uploadWithFormKey } from "services/upload-file.service";

function CustomerForm() {
  const { formKey } = useParams();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [showFormError, setShowFormError] = useState(false);
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    city: "",
    country: "",
    zipCode: "",
    iban: "",
    iban2: "",
    vatCode: "",
    vatCode2: "",
    phone: "",
    email: "",
    website: "",
    bankName: "",
    swiftCode: "",
    companyCode: "",
    contactFullName: "",
    contactPhone: "",
    contactPosition: "",
    contactEmail: "",
    signatureFullName: "",
    signaturePosition: "",
    signatureEmail: "",
    signatureBasisOfRepresentation: "",
    invoiceEmail: "",
    paymentsFullName: "",
    paymentsPhone: "",
    paymentsPosition: "",
    paymentsEmail: "",
    signerFullName: "",
    officeAddress: "",
    uploadedFiles: [],
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [differentContactPerson, setDifferentContactPerson] = useState(false);
  const [differentPaymentsPerson, setDifferentPaymentsPerson] = useState(false);

  const { t } = useTranslation();

  const handleChange = useCallback(
    (field) => (value) => {
      setFormData({ ...formData, [field]: value });
    },
    [formData]
  );

  const toggleOpenFileDialog = useCallback(
    // eslint-disable-next-line no-shadow
    () => setOpenFileDialog((openFileDialog) => !openFileDialog),
    []
  );

  const handleDropZoneDrop = useCallback(
    async (acceptedFiles) => {
      setIsFileUploadLoading(true);
      try {
        const uploadedFiles = [...formData.uploadedFiles];
        for (let i = 0; i < acceptedFiles.length; i++) {
          const { data } = await uploadWithFormKey(acceptedFiles[i], formKey);
          uploadedFiles.push(data);
        }
        handleChange("uploadedFiles")(uploadedFiles);
      } catch (e) {
        console.log(e);
      }
      setIsFileUploadLoading(false);
    },
    [formKey, formData.uploadedFiles, handleChange]
  );

  const handleFileDelete = useCallback(
    (fileId) => {
      const updatedFiles = formData.uploadedFiles.filter(
        ({ id }) => id !== fileId
      );
      handleChange("uploadedFiles")(updatedFiles);
    },
    [formData.uploadedFiles, handleChange]
  );

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = t("customer_form-name_required");
    if (!formData.companyCode)
      errors.companyCode = t("customer_form-companyCode_required");
    if (!formData.phone) errors.phone = t("customer_form-phone_required");
    if (!formData.email) errors.email = t("customer_form-email_required");
    if (!formData.address) errors.address = t("customer_form-address_required");
    if (!formData.city) errors.city = t("customer_form-city_required");
    if (!formData.country) errors.country = t("customer_form-country_required");
    if (!formData.zipCode) errors.zipCode = t("customer_form-zipCode_required");
    if (!formData.bankName)
      errors.bankName = t("customer_form-bankName_required");
    if (!formData.iban) errors.iban = t("customer_form-iban_required");
    if (!formData.signatureFullName)
      errors.signatureFullName = t("customer_form-signature_fullName_required");
    if (!formData.signaturePosition)
      errors.signaturePosition = t("customer_form-signature_position_required");
    if (!formData.signatureEmail)
      errors.signatureEmail = t("customer_form-signature_email_required");
    if (!formData.signatureBasisOfRepresentation)
      errors.signatureBasisOfRepresentation = t(
        "customer_form-signature_basis_of_representation_required"
      );
    if (!formData.invoiceEmail)
      errors.invoiceEmail = t("customer_form-invoice_email_required");
    if (!formData.signerFullName)
      errors.signerFullName = t("customer_form-signer_fullName_required");

    if (differentContactPerson) {
      if (!formData.contactFullName)
        errors.contactFullName = t("customer_form-contact_fullName_required");
      if (!formData.contactPhone)
        errors.contactPhone = t("customer_form-contact_phone_required");
      if (!formData.contactPosition)
        errors.contactPosition = t("customer_form-contact_position_required");
      if (!formData.contactEmail)
        errors.contactEmail = t("customer_form-contact_email_required");
    }

    if (differentPaymentsPerson) {
      if (!formData.paymentsFullName)
        errors.paymentsFullName = t("customer_form-payments_fullName_required");
      if (!formData.paymentsPhone)
        errors.paymentsPhone = t("customer_form-payments_phone_required");
      if (!formData.paymentsPosition)
        errors.paymentsPosition = t("customer_form-payments_position_required");
      if (!formData.paymentsEmail)
        errors.paymentsEmail = t("customer_form-payments_email_required");
    }

    if (formData.uploadedFiles.length === 0) {
      errors.documents = t("customer_form-documents_required");
    }

    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setShowFormError(true);
      return;
    }
    setShowFormError(false);

    const payload = {
      ...formData,
      address: `${formData.address}, ${formData.city}, ${formData.zipCode}, ${formData.country}`,
    };

    try {
      await axios.put(`${API_URL}/customers/form-key/${formKey}`, payload);
      // Add success handling here if needed
      setIsSuccess(true);
    } catch (e) {
      console.error(e);
      // Add error handling here if needed
    }
  };

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const customerEntity = await axios
          .get(`${API_URL}/customers/form-key/${formKey}`)
          .then((response) => response.data);

        if (customerEntity) {
          setFormData({ ...customerEntity, uploadedFiles: [] });
        } else {
          setIsError(true);
        }
        setIsLoading(false);
      } catch (e) {
        setIsError(true);
        console.error(e);
      }
    };

    fetchCustomerData();
  }, [formKey]);

  if (isError) {
    return (
      <EmptyState
        heading={t("customer_form-error_heading")}
        image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
      >
        <p>{t("customer_form-error_message")}</p>
      </EmptyState>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isSuccess) {
    return (
      <EmptyState
        heading={t("customer_form-success_heading")}
        image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
      >
        <p>{t("customer_form-success_text")}</p>
      </EmptyState>
    );
  }

  return (
    <Page title={t("customer_form")}>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <Card roundedAbove="sm">
            <BlockStack gap="200">
              <InlineGrid columns="1fr auto">
                <Text as="h2" variant="headingSm">
                  {t("customer_form-general_info")}
                </Text>
              </InlineGrid>
              <div className="customer-form-grid">
                <BlockStack gap="500">
                  <TextField
                    label={t("customer_form-name")}
                    value={formData.name}
                    onChange={handleChange("name")}
                    requiredIndicator
                    error={validationErrors.name}
                    helpText={t("customer_form-name_help")}
                  />
                  <TextField
                    label={t("customer_form-companyCode")}
                    value={formData.companyCode}
                    onChange={handleChange("companyCode")}
                    requiredIndicator
                    error={validationErrors.companyCode}
                    helpText={t("customer_form-companyCode_help")}
                  />
                  <TextField
                    label={t("customer_form-vatCode")}
                    value={formData.vatCode}
                    onChange={handleChange("vatCode")}
                    helpText={t("customer_form-vatCode_help")}
                  />
                  <TextField
                    label={t("customer_form-vatCode2")}
                    value={formData.vatCode2}
                    onChange={handleChange("vatCode2")}
                    helpText={t("customer_form-vatCode2_help")}
                  />
                  <Bleed marginInline="400">
                    <Box
                      background="bg-surface-secondary"
                      paddingBlock="200"
                      paddingInline="400"
                    >
                      <Text as="h3" variant="headingSm">
                        {t("customer_form-reg_address")}
                      </Text>
                      <Select
                        label={t("customer_form-country")}
                        options={[
                          { value: "LT", label: t("Lithuania") },
                          { value: "LV", label: t("Latvia") },
                          { value: "PL", label: t("Poland") },
                          { value: "EE", label: t("Estonia") },
                        ]}
                        onChange={handleChange("country")}
                        value={formData.country}
                        requiredIndicator
                        error={validationErrors.country}
                        helpText={t("customer_form-country_help")}
                      />
                      <TextField
                        label={t("customer_form-city")}
                        value={formData.city}
                        onChange={handleChange("city")}
                        requiredIndicator
                        error={validationErrors.city}
                        helpText={t("customer_form-city_help")}
                      />
                      <TextField
                        label={t("customer_form-address")}
                        value={formData.address}
                        onChange={handleChange("address")}
                        requiredIndicator
                        error={validationErrors.address}
                        helpText={t("customer_form-address_help")}
                      />
                      <TextField
                        label={t("customer_form-zipCode")}
                        value={formData.zipCode}
                        onChange={handleChange("zipCode")}
                        requiredIndicator
                        error={validationErrors.zipCode}
                        helpText={t("customer_form-zipCode_help")}
                      />
                    </Box>
                  </Bleed>
                  <TextField
                    label={t("customer_form-office_address")}
                    value={formData.officeAddress}
                    onChange={handleChange("officeAddress")}
                    helpText={t("customer_form-office_address_help")}
                  />
                </BlockStack>
                <BlockStack gap="500">
                  <TextField
                    label={t("customer_form-phone")}
                    value={formData.phone}
                    onChange={handleChange("phone")}
                    requiredIndicator
                    error={validationErrors.phone}
                    helpText={t("customer_form-phone_help")}
                  />
                  <TextField
                    label={t("customer_form-email")}
                    value={formData.email}
                    onChange={handleChange("email")}
                    requiredIndicator
                    error={validationErrors.email}
                    helpText={t("customer_form-email_help")}
                  />
                  <TextField
                    label={t("customer_form-website")}
                    value={formData.website}
                    onChange={handleChange("website")}
                    helpText={t("customer_form-website_help")}
                  />
                  <TextField
                    label={t("customer_form-bankName")}
                    value={formData.bankName}
                    onChange={handleChange("bankName")}
                    requiredIndicator
                    error={validationErrors.bankName}
                    helpText={t("customer_form-bankName_help")}
                  />
                  <TextField
                    label={t("customer_form-iban")}
                    value={formData.iban}
                    onChange={handleChange("iban")}
                    requiredIndicator
                    error={validationErrors.iban}
                    helpText={t("customer_form-iban_help")}
                  />
                  <TextField
                    label={t("customer_form-iban2")}
                    value={formData.iban2}
                    onChange={handleChange("iban2")}
                    helpText={t("customer_form-iban2_help")}
                    placeholder={t("customer_form-iban2_placeholder")}
                  />
                  <TextField
                    label={t("customer_form-swiftCode")}
                    value={formData.swiftCode}
                    onChange={handleChange("swiftCode")}
                    helpText={t("customer_form-swiftCode_help")}
                  />
                </BlockStack>
              </div>
            </BlockStack>
          </Card>
          <Card roundedAbove="sm">
            <BlockStack gap="200">
              <InlineGrid columns="1fr auto">
                <Text as="h3" variant="headingSm">
                  {t("customer_form-signature_details")}
                </Text>
              </InlineGrid>
              <Bleed marginInline="400">
                <Box
                  background="bg-surface-secondary"
                  paddingBlock="200"
                  paddingInline="400"
                >
                  <div className="customer-form-grid">
                    <BlockStack gap="500">
                      <TextField
                        label={t("customer_form-signature_fullName")}
                        value={formData.signatureFullName}
                        onChange={handleChange("signatureFullName")}
                        requiredIndicator
                        error={validationErrors.signatureFullName}
                        helpText={t("customer_form-signature_fullName_help")}
                      />
                      <TextField
                        label={t("customer_form-signature_position")}
                        value={formData.signaturePosition}
                        onChange={handleChange("signaturePosition")}
                        requiredIndicator
                        error={validationErrors.signaturePosition}
                        helpText={t("customer_form-signature_position_help")}
                      />
                    </BlockStack>
                    <BlockStack gap="500">
                      <TextField
                        label={t("customer_form-signature_email")}
                        type="email"
                        value={formData.signatureEmail}
                        onChange={handleChange("signatureEmail")}
                        requiredIndicator
                        error={validationErrors.signatureEmail}
                        helpText={t("customer_form-signature_email_help")}
                      />
                      <TextField
                        label={t(
                          "customer_form-signature_basis_of_representation"
                        )}
                        value={formData.signatureBasisOfRepresentation}
                        onChange={handleChange(
                          "signatureBasisOfRepresentation"
                        )}
                        requiredIndicator
                        error={validationErrors.signatureBasisOfRepresentation}
                        helpText={t(
                          "customer_form-signature_basis_of_representation_help"
                        )}
                      />
                    </BlockStack>
                  </div>
                </Box>
              </Bleed>
              <br />
              <Checkbox
                label={t("customer_form-different_contact_person")}
                checked={differentContactPerson}
                onChange={setDifferentContactPerson}
              />
              {differentContactPerson && (
                <>
                  <Text as="h2" variant="headingSm">
                    {t("customer_form-contacts")}
                  </Text>
                  <div className="customer-form-grid">
                    <BlockStack gap="500">
                      <TextField
                        label={t("customer_form-contact_fullName")}
                        value={formData.contactFullName}
                        onChange={handleChange("contactFullName")}
                        requiredIndicator
                        error={validationErrors.contactFullName}
                        helpText={t("customer_form-contact_fullName_help")}
                      />
                      <TextField
                        label={t("customer_form-contact_phone")}
                        value={formData.contactPhone}
                        onChange={handleChange("contactPhone")}
                        requiredIndicator
                        error={validationErrors.contactPhone}
                        helpText={t("customer_form-contact_phone_help")}
                      />
                    </BlockStack>
                    <BlockStack gap="500">
                      <TextField
                        label={t("customer_form-contact_position")}
                        value={formData.contactPosition}
                        onChange={handleChange("contactPosition")}
                        requiredIndicator
                        error={validationErrors.contactPosition}
                        helpText={t("customer_form-contact_position_help")}
                      />
                      <TextField
                        label={t("customer_form-contact_email")}
                        type="email"
                        value={formData.contactEmail}
                        onChange={handleChange("contactEmail")}
                        requiredIndicator
                        error={validationErrors.contactEmail}
                        helpText={t("customer_form-contact_email_help")}
                      />
                    </BlockStack>
                  </div>
                </>
              )}
            </BlockStack>
          </Card>
          <Card roundedAbove="sm">
            <BlockStack gap="200">
              <InlineGrid columns="1fr auto">
                <Text as="h2" variant="headingSm">
                  {t("customer_form-other_details")}
                </Text>
              </InlineGrid>
              <div className="customer-form-grid">
                <BlockStack gap="500">
                  <TextField
                    label={t("customer_form-invoice_email")}
                    value={formData.invoiceEmail}
                    onChange={handleChange("invoiceEmail")}
                    helpText={t("customer_form-invoice_email_help")}
                    requiredIndicator
                    error={validationErrors.invoiceEmail}
                  />
                </BlockStack>
                <BlockStack gap="500"></BlockStack>
              </div>
            </BlockStack>
          </Card>
          <Card roundedAbove="sm">
            <Checkbox
              label={t("customer_form-different_payments_person")}
              checked={differentPaymentsPerson}
              onChange={setDifferentPaymentsPerson}
            />
            {differentPaymentsPerson && (
              <BlockStack gap="200">
                <InlineGrid columns="1fr auto">
                  <Text as="h2" variant="headingSm">
                    {t("customer_form-payments")}
                  </Text>
                </InlineGrid>
                <div className="customer-form-grid">
                  <BlockStack gap="500">
                    <TextField
                      label={t("customer_form-payments_fullName")}
                      value={formData.paymentsFullName}
                      onChange={handleChange("paymentsFullName")}
                      requiredIndicator
                      error={validationErrors.paymentsFullName}
                      helpText={t("customer_form-payments_fullName_help")}
                    />
                    <TextField
                      label={t("customer_form-payments_position")}
                      value={formData.paymentsPosition}
                      onChange={handleChange("paymentsPosition")}
                      requiredIndicator
                      error={validationErrors.paymentsPosition}
                      helpText={t("customer_form-payments_position_help")}
                    />
                  </BlockStack>
                  <BlockStack gap="500">
                    <TextField
                      label={t("customer_form-payments_phone")}
                      value={formData.paymentsPhone}
                      onChange={handleChange("paymentsPhone")}
                      requiredIndicator
                      error={validationErrors.paymentsPhone}
                      helpText={t("customer_form-payments_phone_help")}
                    />
                    <TextField
                      label={t("customer_form-payments_email")}
                      type="email"
                      value={formData.paymentsEmail}
                      onChange={handleChange("paymentsEmail")}
                      requiredIndicator
                      error={validationErrors.paymentsEmail}
                      helpText={t("customer_form-payments_email_help")}
                    />
                  </BlockStack>
                </div>
              </BlockStack>
            )}
          </Card>
          <Card roundedAbove="sm">
            <BlockStack gap="200">
              <InlineGrid columns="1fr auto">
                <Text as="h2" variant="headingSm">
                  {t("customer_form-documents")}
                </Text>
                <Button
                  loading={isFileUploadLoading}
                  onClick={toggleOpenFileDialog}
                >
                  {t("upload")}
                </Button>
                <div style={{ display: "none" }}>
                  <DropZone
                    allowMultiple={true}
                    openFileDialog={openFileDialog}
                    onDropAccepted={handleDropZoneDrop}
                    onFileDialogClose={toggleOpenFileDialog}
                  >
                    <DropZone.FileUpload />
                  </DropZone>
                </div>
              </InlineGrid>
              <Text as="p" tone={validationErrors.documents ? "critical" : ""}>
                Reikalingi pateikti dokumentai:
                <ul>
                  <li>Įmonės registracijos dokumentų išrašo kopija</li>
                  <li>Paskutinės finansinės ataskaitos kopija</li>
                  <li>Nurodytos banko sąskaitos patvirtinimo įrodymas</li>
                  <li>Įmonės įgaliojimas, jeigu veikiama bus su įgaliojimu</li>
                </ul>
              </Text>
              <ResourceList
                resourceName={{
                  singular: t("customer_form-document"),
                  plural: t("customer_form-documents"),
                }}
                items={formData.uploadedFiles}
                renderItem={(item) => {
                  const { id, filename } = item;

                  return (
                    <ResourceItem id={id}>
                      <InlineGrid columns="1fr auto">
                        <Text variant="bodyMd" fontWeight="bold" as="h3">
                          {filename}
                        </Text>
                        <Button
                          onClick={() => {
                            if (
                              window.confirm(
                                t("customer_form-delete_confirmation")
                              )
                            ) {
                              handleFileDelete(id);
                            }
                          }}
                          destructive
                        >
                          {t("customer_form-delete")}
                        </Button>
                      </InlineGrid>
                    </ResourceItem>
                  );
                }}
              />
            </BlockStack>
          </Card>
          <Card roundedAbove="sm" className="customer_form-signature_block">
            <BlockStack gap="200">
              <BlockStack gap="200">
                <Text as="p">
                  <strong>Patvirtinu,</strong> kad man, mano vadovaujamam (-ai)
                  (atstovaujamam (-ai)) Bendrovei ir tiesiogiai ar netiesiogiai
                  jį/ją valdančiam (-tiems) (kontroliuojančiam (-tiems)), kuriam
                  (-iems) asmeniui (-ims), taip pat man žinomiems bendrovės
                  tiekėjams, subtiekėjams, subrangovams ir/ar kitiems
                  tretiesiems asmenims, jeigu tokie yra pasitelkiami sutarties
                  vykdymui, ir/ar jų vykdomos veiklos/tiekiamų produktų
                  atžvilgiu, Sutarties sudarymo momentu nėra taikomos
                  tarptautinės (tame tarpe ir Jungtinių Amerikos Valstijų)
                  sankcijos ar kitos Europos Sąjungos (toliau – ES) nustatytos
                  ribojamosios priemonės.
                </Text>
                <Text as="p">
                  <strong>Man žinoma,</strong> kad jeigu ši deklaracija yra
                  neteisinga, melaginga ir (ar) klaidinanti, su mano vadovaujama
                  (atstovaujama) bendrove sudaryta Sutartis gali būti nutraukta
                  ir mano vadovaujama (atstovaujama) bendrovė privalės atlyginti
                  dėl šios melagingos deklaracijos pateikimo patirtus
                  nukentėjusios šalies nuostolius.
                </Text>
                <Text as="p">
                  <strong>Paaiškėjus</strong> apie man, mano vadovaujamam
                  (atstovaujamam) asmeniui, tiesiogiai ar netiesiogiai jį
                  valdančiam (-tiems) (kontroliuojančiam (-tiems)) asmeniui
                  (-ims), taip pat bendrovės tiekėjams, subtiekėjams,
                  subrangovams ir/ar kitiems tretiesiems asmenims, jeigu tokie
                  yra pasitelkiami sutarties vykdymui, ir/ar jų vykdomos
                  veiklos/tiekiamų produktų atžvilgiu, taikomas tarptautines
                  (tame tarpe ir Jungtinių Amerikos Valstijų) sankcijas ar kitas
                  ES nustatytas ribojamąsias priemones, įsipareigoju nedelsiant
                  apie tai Jus informuoti.
                </Text>
                <Text as="p">
                  <strong>
                    Sutinku, kad sutartys, sąskaitos ir jų priedai būtų
                    siunčiamos tik elektroniniu būdu, šioje Anketoje nurodytais
                    elektroninio pašto adresais.
                  </strong>
                  <br />
                  Jeigu nesutinkate su sutarčių, sąskaitų ir jų priedų gavimu
                  elektroninėje formoje - Pardavėjas pasilieka teisę
                  apmokestinti pašto išlaidas.
                </Text>
              </BlockStack>
              <Bleed marginInline="400">
                <Box
                  background="bg-surface-secondary"
                  paddingBlock="300"
                  paddingInline="400"
                >
                  <InlineStack align="end" gap="200">
                    <div style={{ width: "360px" }}>
                      <TextField
                        label={t("customer_form-signer-positionAndFullName")}
                        value={formData.signerFullName}
                        onChange={handleChange("signerFullName")}
                        autoComplete="off"
                        requiredIndicator
                        error={validationErrors.signerFullName}
                      />
                    </div>
                    <Button variant="primary" submit>
                      {t("customer_form-sign")}
                    </Button>
                  </InlineStack>
                  {showFormError && (
                    <InlineStack align="end" gap="200">
                      <Text tone="critical">
                        {t("customer_form-submit_error")}
                      </Text>
                    </InlineStack>
                  )}
                </Box>
              </Bleed>
            </BlockStack>
          </Card>
        </FormLayout>
      </Form>
    </Page>
  );
}

export default CustomerForm;
