import { useTranslation } from "react-i18next";
import DataTable from "components/datatable/Datatable";
import { ProductForCustomer } from "types/Product";


function Products({
  products,
  isProjectTbl,
}: {
  products: ProductForCustomer[];
  isProjectTbl?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      {!isProjectTbl ? (
        <DataTable
          collection={products}
          columns={[
            { key: "#" },
            { key: "productName", name: t("name"), mobileView: "topLeft"},
            {
              key: "totalQuantity",
              isDisabledTotal: true,
              type: "number",
              name: t("total_quantity"),
              mobileView: "topRight"
            },
            { key: "totalAmount", type: "currency", name: t("total_amount"), mobileView: "bottomRight" },
            {
              key: "invoiceCount",
              isDisabledTotal: true,
              type: "number",
              name: t("invoice_count"),
            },
          ]}
        />
      ) : (
        <DataTable
          collection={products}
          columns={[
            { key: "title", name: t("name") },
            {
              key: "quantity",
              isDisabledTotal: true,
              type: "number",
              name: t("total_quantity"),
            },
            { key: "price", type: "currency", name: t("price") },
            { key: "totalPrice", type: "currency", name: t("total_amount") },
          ]}
        />
      )}
    </>
  );
}

export default Products;
