import { useEffect, useState, useCallback } from "react";
import { useAppDispatch } from "hooks";
import { FormLayout, TextField, Form, Button, Banner } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchProjectBoard } from "containers/project-board/project-board-container.slice";
import {
  createProjectBoard,
  updateProjectBoard,
  fetchProjectBoardById,
} from "./project-board-column-form.service";

function ProjectBoardColumnForm({ onSubmit, projectBoardId, id }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [name, setName] = useState();
  const [order, setOrder] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [errMsg, setErrMsg] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async () => {
    const formData = {
      id,
      projectBoardId,
      name,
      order,
    };
    const action = id ? updateProjectBoard : createProjectBoard;
    try {
      setIsLoading(true);
      const result = await action(formData, companyId);
      dispatch(fetchProjectBoard(companyId, projectBoardId));
      setSuccessMessage("ok");
      if (onSubmit) {
        onSubmit(result);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  const fetchData = useCallback(
    async (id) => {
      const data = await fetchProjectBoardById(companyId, id);
      setName(data.name);
      setOrder(data.order);
    },
    [companyId]
  );

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id, fetchData]);

  return (
    <Form className="project-board-column-form">
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div className="">
              <Banner status="critical">{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div className="">
              <Banner status="info">{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <TextField
          label={t("project-board-column-name")}
          id="name"
          name="name"
          value={name}
          onChange={setName}
        />
        <TextField
          label={t("project-board-column-order")}
          id="order"
          name="order"
          value={order}
          onChange={setOrder}
        />
        <Button
          primary
          onClick={handleFormSubmit}
          disabled={!name}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
}

export default ProjectBoardColumnForm;
