import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Select } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { fetchSeries } from "services/series.service";

function SeriesSelect({ value, onChange, isRequired, name }) {
  const { t } = useTranslation();
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams();
  const [error, setError] = useState("");

  const handleChange = (selectedValue) => {
    if (isRequired && !selectedValue) {
      setError(t("field_is_required"));
    } else {
      setError("");
    }

    onChange(selectedValue, name);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const data = await fetchSeries(companyId);
        const dataCollection = _.map(data, (row) => ({
          label: row.title,
          value: row.id,
        }));
        dataCollection.unshift({ label: " - ", value: "" });
        setCollection(dataCollection);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [companyId]);

  return (
    <div className="series-select">
      <Select
        label={t("select_series")}
        options={collection}
        value={value}
        disabled={isLoading}
        onChange={handleChange}
        error={error}
      />
    </div>
  );
}

export default SeriesSelect;

SeriesSelect.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  allowMultiple: PropTypes.bool,
};

SeriesSelect.defaultProps = {
  isRequired: false,
};
