import React, {
  useState,
  useEffect,
  useRef,
  CSSProperties,
  ReactElement,
  useCallback,
} from "react";
import { Tree, NodeRendererProps } from "react-arborist";
import {
  Layout,
  Page,
  Card,
  Text,
  BlockStack,
  InlineStack,
  Button,
  Icon,
  EmptyState,
} from "@shopify/polaris";
import { EditIcon, PlusIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import CategoryFiles from "containers/document/category-files/categoryFiles";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  fetchCategoryDetails,
  setDestroy,
} from "containers/document/category-tree/category-tree.Slice";
import { RootState, AppDispatch } from "configureStore";
import "./categoryTree.css";
import { Routes, Route } from "react-router-dom";
import { Category, CategoryDataTbl } from "types/Document";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "components/modalx/modalx.slice";
import useResizeObserver from "use-resize-observer";

const LOCAL_STORAGE_KEY = 'document-tree-state';

const DocumentArborist: React.FC = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const [activeNodeId, setActiveNodeId] = useState<string>("");
  const [expandedNodes, setExpandedNodes] = useState<Record<string, boolean>>({});
  const { categoriesData } = useSelector(
    (state: RootState) => state.documentCategoryReducer
  );
  const { isUserMaster } = useSelector((state: RootState) => state.companySelectReducer);

  const { ref, height } = useResizeObserver();

  const dispatch: AppDispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();

  useEffect(() => {
    const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      setActiveNodeId(parsedState.activeNodeId || "");
      setExpandedNodes(parsedState.expandedNodes || {});
    }
  }, []);

  const loadTreeFileCategoriesData = useCallback(() => {
    if (companyId) {
      dispatch(fetchCategoryDetails({ companyId }));
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    loadTreeFileCategoriesData();
    setActiveNodeId("");

    return () => {
      setDestroy();
    };
  }, [companyId, loadTreeFileCategoriesData]);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({ activeNodeId, expandedNodes })
    );
  }, [activeNodeId, expandedNodes]);

  function transformData(data: Category[]): CategoryDataTbl[] {
    return data?.map((item) => ({
      id: item.id,
      name: item.title,
      count: item.fileCount,
      parentId: item.parentCategory,
      children: item.subcategories ? transformData(item.subcategories) : [],
      order: item.order,
    })).sort((a, b) => (Number(a.order) ?? 0) - (Number(b.order) ?? 0));
  }

  const transformedData: CategoryDataTbl[] = transformData(categoriesData);

  const getDynamicURIName = (url: string) => {
    const urlParts = url.split("/");
    const dynamicName = urlParts[urlParts.length - 1];
    return dynamicName;
  };

  let location = useLocation();
  const dynamicRoute = getDynamicURIName(location.pathname);

  useEffect(() => {
    if (dynamicRoute !== "documents") {
      setActiveNodeId(dynamicRoute);
      history(`${dynamicRoute}`);
    } else {
      setActiveNodeId("");
      setExpandedNodes({});
    }
  }, [dynamicRoute, history]);

  const handleClick = (node: any) => {
    setActiveNodeId(node.id);
    toggleNode(node.id);
    history(`${node.id}`);
  };

  // Toggle the expanded state of a node
  const toggleNode = (nodeId: string) => {
    setExpandedNodes((prev) => ({
      ...prev,
      [nodeId]: !prev[nodeId],
    }));
  };

  const handleToggle = () => {
    // console.log(node, "node");
  };

  const nodeRef = useRef<HTMLDivElement>(null);

  const handleCreateFilesCategory = () => {
    dispatch(setModalxComponent("AddFilesCategoryForm"));
  };

  const handleEditFilesCategory = ({ id }: { id: string }) => {
    dispatch(setModalxComponent("AddFilesCategoryForm"));
    dispatch(setModalxComponentProps({ id }));
  };

  const handleAddNode = (parentId: string) => {
    dispatch(setModalxComponent("AddFilesCategoryForm"));
    dispatch(setModalxComponentProps({ parentId }));
  };

  const renderNode = ({
    node,
    style,
    dragHandle,
  }: NodeRendererProps<CategoryDataTbl>): ReactElement => {
    const isActive = node.id === activeNodeId;
    const isExpanded = expandedNodes[node.id] || false; // Check if node is expanded from localStorage

    // Ensure node is opened if it should be expanded
    if (isExpanded && !node.isOpen) {
      node.toggle(); // Ensure node is opened
    }

    return (
      <div
        style={style as CSSProperties}
        ref={nodeRef}
        tabIndex={0}
        onClick={() => handleClick(node)}
        className={isActive ? "node_txt active-node" : "node_txt"}
      >
        <InlineStack gap="100">
          <span style={{ display: "inline-flex", alignItems: "center" }}>
            {node.children!.length! > 0 ? (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleNode(node.id);
                  node.toggle();
                }}
                style={{ marginRight: "8px" }}
              >
                {node.isOpen ? "-" : "+"}
              </button>
            ) : (
              <button
                disabled
                className="disabled_btn"
                style={{ marginRight: "8px" }}
              ></button>
            )}
            <span
              style={{
                display: node.data.name.length ? "inline-block" : "none",
                whiteSpace: "nowrap",
                // overflow: "hidden",
                // textOverflow: "ellipsis",
                // maxWidth: "150px",
              }}
              title={node.data.name}
            >
              {node.data.name}
            </span>
            &nbsp;[{node.data.count <= 0 ? "x" : node.data.count}]
            {isUserMaster && <>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditFilesCategory({ id: node.id });
                }}
                style={{ marginLeft: "8px" }}
              >
                <Icon source={EditIcon} tone="base" />
              </span>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddNode(node.id);
                }}
                style={{ marginLeft: "8px" }}
              >
                <Icon source={PlusIcon} tone="base" />
              </span></>
            }
          </span>
        </InlineStack>
      </div>
    );
  };

  return (
    <Page fullWidth>
      <Layout>
        <Layout.Section variant="oneThird">
          <BlockStack gap="500">
            <Card roundedAbove="sm">
              <InlineStack align="space-between">
                <Text as="h2" variant="headingSm">
                  {t("category")}
                </Text>
                {isUserMaster && <Button onClick={handleCreateFilesCategory}>{t("add")}</Button>}
              </InlineStack>
              <div className="arborist-container" ref={ref}>
                <Tree
                  data={transformedData}
                  onToggle={handleToggle}
                  disableMultiSelection
                  idAccessor="id"
                  openByDefault={false}
                  rowHeight={30}
                  height={height}
                  // width={width}
                >
                  {renderNode}
                </Tree>
              </div>
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section>
          {activeNodeId ? (
            <BlockStack gap="500">
              <Card roundedAbove="sm">
                <Routes>
                  <Route
                    path={activeNodeId}
                    element={<CategoryFiles id={activeNodeId} />}
                  />
                </Routes>
              </Card>
            </BlockStack>
          ) : (
            <BlockStack gap="500">
              <Card roundedAbove="sm">
                <EmptyState
                  // heading={t('no_categories')}
                  image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                ></EmptyState>
              </Card>
            </BlockStack>
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default DocumentArborist;
