import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchById } from "services/company.service";
import { CompanyEntity } from 'types/Company';

// Define the types for your initial state
interface CompanyContainerState {
  isLoading: boolean;
  isError: boolean;
  errMsg: string;
  companyEntity: CompanyEntity | null;
}

// Define the initial state
const initialState: CompanyContainerState = {
  isLoading: true,
  isError: false,
  errMsg: "",
  companyEntity: null,
};

// Create the slice
const CompanyContainerSlice = createSlice({
  name: "Company_container",
  initialState,
  reducers: {
    setDestroy() {
      return initialState;
    },
    setLoading(state: CompanyContainerState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(
      state: CompanyContainerState,
      action: PayloadAction<{ error: string }>
    ) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setCompanyContainer(state: CompanyContainerState, action: PayloadAction<any>) {
      state.companyEntity = action.payload;
      state.isLoading = false;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = CompanyContainerSlice;

// Extract and export each action creator by name
export const { setDestroy, setLoading, setError, setCompanyContainer } = actions;

// Export the reducer, either as a default or named export
export default reducer;

// Define the type for the dispatch function
export const fetchCompanyDetails =
  ({ companyId }: { companyId: string }) =>
  async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const data = await fetchById(companyId);
      dispatch(setCompanyContainer(data.companyEntity));
    } catch (e) {
      const error = "not_fetch";
      dispatch(setError({ error }));
      return false;
    } finally {
      dispatch(setLoading(false));
    }
  };
