import React from "react";
import PropTypes from "prop-types";
import OrderItem from "./order-item/order-item";
import _ from "lodash";
import { Card, IndexTable, Button } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { Item } from "types/Order";
import Currency from "../../components/format/currency/currency";
import "./style.scss";

type Props = {
  items: Array<Item>;
  onChange: Function;
};

function OrderItems({ items, onChange }: Props) {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const resourceName = {
    singular: "orderitem",
    plural: "orderitems",
  };

  const handleOrderItemChange = (item: Item) => {
    const newItems = [...items];

    _.forEach(newItems, ({ id }, index) => {
      if (id === item.id) {
        newItems[index] = item;
      }
    });
    onChange(newItems);
  };

  const handleOrderItemRemove = (id: string) => {
    const filteredItems = _.filter(items, (item) => item.id !== id);
    onChange(filteredItems);
  };

  const handleAddOrderItem = () => {
    const newOrderItem = {
      id: uuidv4(),
      quantity: 0,
      unitPrice: 0,
      vat: "",
      description: "",
      order: orderId,
      product: "",
    };

    onChange([...items, newOrderItem]);
  };

  const total = _.map(
    items,
    (item) => parseInt(item.quantity) * parseInt(item.unitPrice)
  ).reduce((a, b) => a + b, 0);

  const rowMarkup = _.map(items, (item, index) => (
    <IndexTable.Row id={item.id} key={item.id} position={index}>
      <OrderItem
        index={index}
        item={item}
        onChange={handleOrderItemChange}
        remove={handleOrderItemRemove}
      />
    </IndexTable.Row>
  ));

  return (
    <Card>
      <IndexTable
        resourceName={resourceName}
        itemCount={items.length}
        selectable={false}
        headings={[
          { title: t("number") },
          { title: t("product") },
          { title: t("quantity") },
          { title: t("unit_price") },
          { title: t("vat") },
          { title: t("description") },
          { title: t("total") },
        ]}
      >
        {rowMarkup}
        {items.length > 1 && (
          <IndexTable.Row id="total" key="total" position={items.length + 1}>
            <IndexTable.Cell></IndexTable.Cell>
            <IndexTable.Cell></IndexTable.Cell>
            <IndexTable.Cell></IndexTable.Cell>
            <IndexTable.Cell></IndexTable.Cell>
            <IndexTable.Cell></IndexTable.Cell>
            <IndexTable.Cell></IndexTable.Cell>
            <IndexTable.Cell>
              <div className="order-items__total">
                <Currency value={total} isSymbol />
              </div>
            </IndexTable.Cell>
            <IndexTable.Cell></IndexTable.Cell>
          </IndexTable.Row>
        )}
      </IndexTable>
      <Button onClick={handleAddOrderItem}>{t("add_order_item")}</Button>
    </Card>
  );
}

export default OrderItems;

OrderItems.propTypes = {
  items: PropTypes.instanceOf(Array),
  onChange: PropTypes.func.isRequired,
};

OrderItems.defaultProps = {
  items: [],
};
