import { createSlice } from "@reduxjs/toolkit";
import { create } from "../company-select/company-select.service";
import { fetchCompanies } from "../company-select/company-select.slice";
import { updateCompany } from "services/company.service";
import { fetchCompanyDetails } from "containers/settings/settings-container.slice";


const initialState = {
  isFormValid: false,
  formControls: {
    name: {
      value: "",
      isValid: false,
      validationRules: {
        isRequired: true,
      },
      isTouched: false,
    },
    legalName: {
      value: "",
      isValid: false,
      validationRules: {
        isRequired: false,
      },
      isTouched: false,
    },
    code: {
      value: "",
      isValid: false,
      validationRules: {
        isRequired: true,
      },
      isTouched: false,
    },
    vat: {
      value: "",
      isValid: false,
      validationRules: {
        isRequired: false,
      },
      isTouched: false,
    },
    address: {
      value: "",
      isValid: false,
      validationRules: {
        isRequired: true,
      },
      isTouched: false,
    },
    description: {
      value: "",
      isValid: false,
      validationRules: {
        isRequired: false,
      },
      isTouched: false,
    },
  },
  isLoading: false,
  isError: false,
  isSuccessfullySaved: false,
  errMsg: null,
}
const LoginSlice = createSlice({
  name: "companyCreate",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setDestroy() {
      return initialState;
    },
    setLoading(state) {
      state.isLoading = true;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setSuccessfullySaved(state) {
      state.isLoading = false;
      state.isError = false;
      state.errMsg = null;
      state.isSuccessfullySaved = true;
    },
    setFormData(state, action) {
      state.formControls = action.payload.validatedFormControls;
      state.isFormValid = action.payload.isFormValid;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = LoginSlice;

// Extract and export each action creator by name
export const { setLoading, setError, setSuccessfullySaved, setFormData, setDestroy } =
  actions;

// Export the reducer, either as a default or named export
export default reducer;

export const submit =
  ({ name, code, vat, address, description, legalName }) =>
  async (dispatch) => {
    dispatch(setLoading());
    try {
      await create({
        name,
        code,
        vat,
        address,
        description,
        legalName,
      });
      dispatch(setSuccessfullySaved());
      dispatch(fetchCompanies());
      window.location.reload(false);
      return true;
    } catch (e) {
      dispatch(setError("400"));
      return false;
    }
  };

export const update =
  (id, { name, code, vat, address, description, legalName }) =>
    async (dispatch) => {
      dispatch(setLoading());
      try {
        await updateCompany({
          name,
          code,
          vat,
          address,
          description,
          legalName,
        }, id);
        dispatch(setSuccessfullySaved());
        dispatch(fetchCompanyDetails({ companyId:id }));
        return true;
      } catch (e) {
        dispatch(setError("400"));
        return false;
      }
};
