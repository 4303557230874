import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ResourceList, ResourceItem, Text, Button, Icon } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import {
  setModalxComponent,
  setModalxComponentProps,
  setModalxStyle
} from "components/modalx/modalx.slice";
import { API_URL } from "../../constants";
import PrettyDate from "components/format/PrettyDate";
import {
  EditIcon,
  ViewIcon,
  CircleDownIcon,
} from '@shopify/polaris-icons';
import { hasPdfExtension } from "utils/helperFunctions";

function Files({ files }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { companyId } = useParams();

  const handleOnClick = (fileId) => {
    dispatch(setModalxComponent("FileForm"));
    dispatch(
      setModalxComponentProps({
        fileId,
        companyId,
      })
    );
  };

  const handlePDFModal = (id) => {
    dispatch(setModalxComponent("PdfView"));
    dispatch(setModalxStyle("large"))
    dispatch(
      setModalxComponentProps({
        id,
        companyId,
      })
    );
  };

  return (
    <ResourceList
      resourceName={{ singular: t("file"), plural: t("files") }}
      items={files}
      renderItem={(item) => {
        const { id, createdAt, title, name, category, user, series, number } =
          item;
        const downloadUrl = `${API_URL}/${companyId}/files/download/${id}`;

        return (
          <ResourceItem id={id} >
            <div className="flex justify-space">
              <Text variant="bodyMd" fontWeight="bold" as="h3">
                [{category?.title || "-"}] - {name || title}
              </Text>
              <Button onClick={() => handleOnClick(id)}>
                <Icon
                  source={EditIcon}
                  tone="base"
                  accessibilityLabel={t('edit')}
                />
              </Button>
            </div>
            <div className="flex justify-space">
              <Text variant="bodyMd" as="p">
                {" "}
                {t("series")} : {series?.title || "-"}-{number || "-"}
              </Text>
              <Button disabled={hasPdfExtension(title) ? false : true} onClick={() => handlePDFModal(id)}>
                <Icon
                  source={ViewIcon}
                  tone="base"
                  accessibilityLabel={t('view')}
                />
              </Button>

            </div>
            <div className="flex justify-space">
              <PrettyDate dateString={createdAt} showTime={true} /> {user?.name}
              <Button url={downloadUrl} target="_blank">
                <Icon
                  source={CircleDownIcon}
                  tone="base"
                  accessibilityLabel={t('download')}
                />
              </Button>
            </div>
          </ResourceItem>
        );
      }}
    />
  );
}

export default Files;

Files.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array.isRequired,
  isCatRequired: PropTypes.bool,
};

Files.defaultProps = {
  isCatRequired: false,
};
