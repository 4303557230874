/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import React from "react";
import { Modal, LegacyStack } from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import UserForm from "features/user-form/user-form";
import CustomerForm from "features/customer-form/customer-form";
import CompanyForm from "features/company-create/company-create";
import TaskCreate from "features/task-create/task-create";
import OrderForm from "features/order-form/order-form";
import VatForm from "features/vat-form/vat-form";
import ProductForm from "features/product-form/product-form";
import BuildPdfForm from "components/customer/build-pdf/BuildPdfForm";
import ProjectBoardForm from "containers/project-board-form/project-board-form";
import { setIsModalxOpen, setDestroy } from "./modalx.slice";
// import './style.scss';
import FilesCategoryForm from "containers/files-category-form/files-category-form";
import FilesTemplateForm from "containers/files-template-form/files-template-form";
import ProjectBoardColumnForm from "containers/project-board-column-form/project-board-column-form";
import ProjectForm from "containers/project-form/project-form";
import ProjectFormView from "containers/project-view-form/project-view";
import FileForm from "containers/file-form/file-form";
import ContactForm from "containers/contact-form/contact-form";
import Loading from "components/loading/Loading";
import AddFilesCategoryForm from "containers/files-categories-add-form/files-category-form";
import CustomFieldForm from "containers/custom-field-form/custom-field-form";
import SeriesForm from "containers/file-series-form/files-series-form";
import PdfView from "components/pdf-view/Pdf_modal";
import FilesTypesForm from "containers/files-types-form/files-types-form";

function Modalx() {
  const {
    title,
    isOpen,
    component,
    primaryAction,
    secondaryActions,
    size,
    componentProps,
  } = useSelector((state) => state.modalxReducer);
  const dispatch = useDispatch();

  const closeModal = (e) => {
    if(e?.target) {
      dispatch(setIsModalxOpen(false));
      dispatch(setDestroy())
    }
  };

  const components = {
    CompanyForm,
    TaskCreate,
    UserForm,
    CustomerForm,
    OrderForm,
    VatForm,
    ProductForm,
    BuildPdfForm,
    ProjectBoardForm,
    ProjectForm,
    FileForm,
    Loading,
    FilesCategoryForm,
    FilesTemplateForm,
    ProjectBoardColumnForm,
    ContactForm,
    ProjectFormView,
    AddFilesCategoryForm,
    CustomFieldForm,
    SeriesForm,
    PdfView,
    FilesTypesForm,
  };

  const ComponentNode = components[component];
  if (!ComponentNode) return null;
  return (
    <Modal
      title={title || null}
      primaryAction={primaryAction || null}
      secondaryActions={secondaryActions || null}
      open={isOpen}
      onClose={closeModal}
      size={size}
    >
      <Modal.Section>
        <LegacyStack vertical>
          <ComponentNode {...componentProps} />
        </LegacyStack>
      </Modal.Section>
    </Modal>
  );
}

export default Modalx;
