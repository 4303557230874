import { useEffect, useCallback } from "react";
import { Page, Card } from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "configureStore";
import {
  fetchSeriesList,
  setDestroy,
} from "containers/files-series/files-series-container.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DataTable from "components/datatable/Datatable";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "components/modalx/modalx.slice";

function SeriesContainer() {
  const { collection } = useSelector((state: RootState) => state.seriesReducer);

  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();

  const fetchData = useCallback(
    (companyId: string) => {
      if (companyId) {
        dispatch(fetchSeriesList(companyId));
      }
    },
    [dispatch]
  );

  const handleCreateSeries = () => {
    dispatch(setModalxComponent("SeriesForm"));
  };

  const onRowClick = ({ id }: { id: string }) => {
    dispatch(setModalxComponent("SeriesForm"));
    dispatch(setModalxComponentProps({ id }));
  };

  useEffect(() => {
    if (companyId) {
      fetchData(companyId);
    }

    return () => {
      setDestroy();
    };
  }, [companyId, fetchData]);

  if (!companyId || !collection) {
    return null;
  }

  return (
    <Page
      fullWidth
      primaryAction={{
        content: t("series_create"),
        onAction: handleCreateSeries,
      }}
      title={t("series")}
    >
      <Card>
        <DataTable
          collection={collection}
          columns={[
            { key: "#" },
            { key: "title", name: t("series_label"), onClick: onRowClick, mobileView: "topLeft"  },
            { key: "description", name: t("description"), onClick: onRowClick, mobileView: "topRight" },
          ]}
        />
      </Card>
    </Page>
  );
}

export default SeriesContainer;
