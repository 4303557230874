import { createSlice } from "@reduxjs/toolkit";
import { fetchById } from "services/product.service";

const initialState = {
  isLoading: true,
  isError: false,
  errMsg: "",
  product: null,
};

const productContainerSlice = createSlice({
  name: "product",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setDestroy() {
      return initialState;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setProduct(state, action) {
      state.product = action.payload;
      state.isLoading = false;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = productContainerSlice;

// Extract and export each action creator by name
export const { setDestroy, setLoading, setError, setProduct } = actions;
// Export the reducer, either as a default or named export
export default reducer;

export const fetchProduct = (companyId, productId) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const { productEntity } = await fetchById(companyId, productId);
    dispatch(setProduct(productEntity));
  } catch (e) {
    dispatch(setError("fetch_error"));
    return false;
  }
};
