import axios from "axios";
import { API_URL } from "constants";

export const createProjectBoard = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/project-board-columns`;
  return axios.post(url, formData).then((response) => response.data);
};

export const updateProjectBoard = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/project-board-columns/${formData.id}`;
  return axios.patch(url, formData).then((response) => response.data);
};

export const fetchProjectBoardById = async (companyId, id) => {
  const url = `${API_URL}/${companyId}/project-board-columns/${id}`;
  return axios.get(url).then((response) => response.data);
};
