import axios from "axios";
import { API_URL } from "constants";

export const fetchProjectById = async (companyId, id) => {
  const url = `${API_URL}/${companyId}/projects/${id}`;
  return axios.get(url).then((response) => response.data);
};

export const updateProject = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/projects/${formData.id}`;
  return axios.patch(url, formData).then((response) => response.data);
};
