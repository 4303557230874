import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "configureStore";
import { FormLayout, TextField, Form, Button, Banner } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchFilesTypesList } from "containers/files-types/files-types-container.slice";
import {
  createFilesTypes,
  updateFilesTypes,
  fetchFilesTypesById,
} from "services/files-types.service";
import Loading from "components/loading/Loading";


const DocumentsTypesForm = ({ id }: { id?: string }) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const [label, setLabel] = useState<string>("");
  const [template, setTemplate] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(true);
  // Error state to store validation errors
  const [errors, setErrors] = useState({
    label: "",
    template: ""
  });

  const handleFormSubmit = async () => {
    const formData = {
      id,
      label,
      template,
      description
    };
    const action = id ? updateFilesTypes : createFilesTypes;
    try {
      setIsLoading(true);
      await action(formData, companyId);
      if (companyId) dispatch(fetchFilesTypesList(companyId));
      setSuccessMessage("ok");
      setIsLoading(false);
    } catch (e) {
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  const fetchData = useCallback(
    async (id: string) => {
      setFetchLoading(true);
      try {
        const { label, template, description } = await fetchFilesTypesById(companyId!, id);
        setLabel(label);
        setTemplate(template);
        setDescription(description);
      } catch (error) {
        setErrMsg("error");
      } finally {
        setFetchLoading(false);
      }
    },
    [companyId]
  );

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id, fetchData]);

  useEffect(() => {
    const validationErrors = { label: "", template: "" };

    // Validate label
    if (!label) {
      validationErrors.label = t("label_is_required");
    }

    // Validate template
    if (!template) {
      validationErrors.template = t("template_is_required");
    }

    setErrors(validationErrors);
  }, [label, template]);

  if (id && fetchLoading) {
    return <Loading />
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div>
              <Banner tone="critical">{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div>
              <Banner tone="info">{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <TextField
          label={t("label")}
          id="label"
          name="label"
          value={label}
          onChange={(value) => setLabel(value)}
          requiredIndicator={true}
          autoComplete="off"
          error={errors.label}
        />
        <TextField
          label={t("template")}
          id="template"
          name="template"
          value={template}
          onChange={setTemplate}
          requiredIndicator={true}
          autoComplete="off"
          error={errors.template}
        />
        <TextField
          label={t("description")}
          id="description"
          name="description"
          value={description}
          onChange={setDescription}
          multiline={4}
          autoComplete="off"
        />
        <Button
          onClick={handleFormSubmit}
          disabled={!label || !template}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
};

export default DocumentsTypesForm;
