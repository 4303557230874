import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Text,
  Card,
  LegacyCard,
  ResourceList,
  ResourceItem,
  Avatar,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { fetchUsers } from "features/users/users.slice";

function UserList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const {
    usersReducer: { usersCollection, isLoading },
  } = useSelector((state) => state);
  const [filtersValue, setFilterValue] = useState("")


  useEffect(() => {
    dispatch(fetchUsers(companyId, filtersValue));
  }, [dispatch, companyId, filtersValue]);

  return (
    <Card>
      <div style={{marginBottom:"10px"}}>
        <Text as="h4" fontWeight="bold" variant="headingLg" alignment="start" >
          {t('users')}
        </Text>
      </div>

      <LegacyCard>
        <ResourceList
          resourceName={{ singular: 'user', plural: 'users' }}
          items={usersCollection}
          loading={isLoading}
          renderItem={(item) => {
            const { id, name, phone, email } = item;
            return (
              <ResourceItem
                verticalAlignment="center"
                id={id}
                // url={"url"}
                media={
                  <Avatar customer size="md" name={name} />
                }
                accessibilityLabel={`View details for ${name}`}
                name={name}
              >
                <Text variant="bodyMd" fontWeight="bold" as="h3">
                  {name}
                </Text>
                <div>{email}</div>
                <div>{phone}</div>
              </ResourceItem>
            );
          }}
        />
      </LegacyCard>
    </Card>
  );
}

export default UserList;
