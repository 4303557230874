import axios from "axios";
import { API_URL } from "../../constants";

let cancelTokenSource;

export const fetch = (companyId, filtersValue) => {
  // Cancel the previous request if it's still active
  if (cancelTokenSource) {
    cancelTokenSource.cancel("Operation canceled due to new request.");
  }

  // Create a new cancel token source for the current request
  cancelTokenSource = axios.CancelToken.source();

  const url = `${API_URL}/${companyId}/products/fetch`;

  return axios
    .post(url, { filtersValue }, { cancelToken: cancelTokenSource.token })
    .then((response) => response.data);
};

export const post = (companyId, data) => {
  const url = `${API_URL}/${companyId}/products/post`;
  return axios.post(url, data).then((response) => response.data);
};

export const fetchById = (companyId, productId) => {
  const url = `${API_URL}/${companyId}/products/fetchById/${productId}`;
  return axios.get(url).then((response) => response.data);
};
