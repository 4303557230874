import { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "common/validator";
import {
  FormLayout,
  TextField,
  Form,
  Card,
  Banner,
  Button,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { sendLoginUrl } from "./login.slice";
import "./login.scss";

function Login({ session, errMsg, isEmailSent, isLoading }) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [isFormValid, setIsFormValid] = useState(false);

  const handleSubmit = async () => {
    if (validateEmail(email)) {
      await dispatch(sendLoginUrl({ email }));
    }
  };

  useEffect(() => {
    if (validateEmail(email)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [email]);

  useEffect(() => {
    if (session) {
      const redirectUrl = _.get(window, "asist.redirect");
      window.asist = null;
      if (redirectUrl) {
        history(redirectUrl);
      } else {
        history("/app");
      }
    }
  });

  if (isEmailSent) {
    return (
      <div className="login-page">
        <Card title={t("login_page_sent_title")} sectioned>
          <p>{t("login_page_sent_text")}</p>
        </Card>
      </div>
    );
  }

  return (
    <div className="login-page">
      <Card
        title={t("Login to application")}
        primaryFooterAction={{
          content: t("Login"),
          loading: isLoading,
          disabled: !isFormValid,
          onAction: handleSubmit,
        }}
        // secondaryFooterActions={[
        //   { content: t("password_restore"), url: "/auth/restore" },
        //   { content: t("signup"), url: "/auth/signup" },
        // ]}
        sectioned
      >
        <Form className="login-page_form" onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              disabled={isLoading}
              label={t("Email")}
              type="email"
              value={email}
              requiredIndicator={true}
              onChange={(value) => setEmail(value)}
              helpText={
                <span>
                  {t(
                    "valid_email_address_is_required_we_will_send_authentication_link_to_this_email"
                  )}
                </span>
              }
            />
            <Button disabled={!isFormValid} submit>
              {t("get_auth_link")}
            </Button>
          </FormLayout>
        </Form>
        <div className="login-page__warnings">
          {errMsg && <Banner status="critical">{t(errMsg)}</Banner>}
        </div>
      </Card>
    </div>
  );
}

export default connect((state) => ({
  session: state.authReducer.session,
  isLoading: state.loginPageReducer.isLoading,
  isEmailSent: state.loginPageReducer.isEmailSent,
  errMsg: state.loginPageReducer.errMsg,
}))(Login);
