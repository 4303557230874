import { useEffect, useCallback } from "react";
import {
  Layout,
  Page,
  Card,
  Text,
  BlockStack,
  TextContainer,
  Button,
  ButtonGroup,
  InlineStack,
  EmptyState,
  Link,
  Spinner,
} from "@shopify/polaris";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PrettyDate from "components/format/PrettyDate";
import UploadFile from "features/upload-file/upload-file";
import Files from "features/files/files";
import { fetchFileDetails, setDestroy } from "containers/file/file.Slice";
import { RootState, AppDispatch } from "configureStore";
import { useSelector, useDispatch } from "react-redux";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "components/modalx/modalx.slice";
import { API_URL } from "../../constants";

function FileContainer() {
  const { t } = useTranslation();
  const { companyId, catId, fileId } = useParams();
  const dispatch: AppDispatch = useDispatch();

  const loadFileData = useCallback(() => {
    if (fileId && companyId) {
      dispatch(fetchFileDetails({ fileId, companyId }));
    }
  }, [fileId, companyId, dispatch]);

  const { fileEntity, isLoading } = useSelector(
    (state: RootState) => state.fileContainerReducer
  );

  useEffect(() => {
    loadFileData();

    return () => {
      dispatch(setDestroy());
    };
  }, [fileId, companyId, loadFileData]);

  const handleOnFileChange = () => {};

  const handleEditClick = (fileId: string) => {
    dispatch(setModalxComponent("FileForm"));
    dispatch(
      setModalxComponentProps({
        fileId,
        companyId,
        isCustomerFormRequired: true,
        categoryType: fileEntity?.category?.type
      })
    );
  };

  // check stritly route param and fetched fileId 
  const fetchedFileId = fileEntity?.id ?? null;
  if (fetchedFileId  !== fileId) {
    return null;
  }

  if(!fileEntity){
     return null
  }

  const customFieldsContent = fileEntity.customFields
    ?.filter(({ value }) => value)
    .map(({ label, value, fieldType }) => (
      fieldType === 'date' ?
      (<Text as="p" variant="bodyMd" key={label}>
      <strong>{t(label)} : </strong><PrettyDate dateString={value}/></Text>)
      :
      (<Text as="p" variant="bodyMd" key={label}>
        <strong>{t(label)} : </strong> {value}
      </Text>)
    ));

  return (
    <Page
      fullWidth
      backAction={{
        content: "File-Page",
        url: `/app/${companyId}/documents/${catId}`,
      }}
      title={fileEntity.name}
      titleMetadata={isLoading && <Spinner size="small" />}
    >
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            <Card roundedAbove="sm">
              <div className="flex">
                <Text as="h2" variant="headingSm">
                  {t("files")}
                </Text>
                <div className="flex-grow"></div>
                <ButtonGroup>
                  <UploadFile
                    onChange={handleOnFileChange}
                    subject="customer"
                    subjectId={fileEntity.subjectId}
                    parentFileId={fileId}
                    categoryId={null}
                    categoryType={fileEntity.category?.type}
                  />
                </ButtonGroup>
              </div>
              {fileEntity.relatedFiles.length > 0 ? (
                <Files files={fileEntity.relatedFiles} />
              ) : (
                <EmptyState
                  heading={t("no_files")}
                  image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                >
                  <UploadFile
                    onChange={handleOnFileChange}
                    subject="customer"
                    subjectId={fileEntity.subjectId}
                    parentFileId={fileId}
                    categoryId={null}
                    categoryType={fileEntity.category?.type}
                  />
                </EmptyState>
              )}
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="500">
            <Card roundedAbove="sm">
              <InlineStack align="space-between">
                <Link
                  url={`${API_URL}/${companyId}/files/download/${fileEntity.id}`}
                  target="_blank"
                  removeUnderline
                >
                  {t("download")}
                </Link>
                <Button
                  variant="plain"
                  onClick={() => handleEditClick(fileEntity.id)}
                  accessibilityLabel="Preview"
                >
                  {t("edit")}
                </Button>
              </InlineStack>
              <TextContainer>
                <Text as="p" variant="bodyMd">
                  <strong>{t("document_name")} : </strong>{" "}
                  {fileEntity?.name || fileEntity.title}
                </Text>
                <Text as="p" variant="bodyMd">
                  <strong>{t("customer")} : </strong> {fileEntity?.customer?.name}
                </Text>
                <Text as="p" variant="bodyMd">
                  <strong>{t("category")} : </strong> {fileEntity.category?.title}
                </Text>
                {fileEntity.assignedUser?.name && (
                  <Text as="p" variant="bodyMd">
                    <strong>{t("responsible_user")} : </strong> {fileEntity.assignedUser?.name}
                  </Text>
                )}
                {fileEntity.user?.name && (
                  <Text as="p" variant="bodyMd">
                    <strong>{t("the_file_was_uploaded_by")} : </strong> {fileEntity.user?.name}
                  </Text>
                )}
                {!fileEntity.externalNumber ? (
                  <></>
                ) : (
                  <Text as="p" variant="bodyMd">
                    <strong>{t("externalNumber")} : </strong>{" "}
                    {fileEntity.externalNumber}
                  </Text>
                )}
                {fileEntity.series && (
                  <Text as="p" variant="bodyMd">
                    <strong>{t("series")} : </strong> {fileEntity.series.title}
                    {fileEntity.number}
                  </Text>
                )}
                {customFieldsContent}
                <Text as="p" variant="bodyMd">
                  <strong>{t("document_date")} : </strong>{" "}
                  {fileEntity.date ? (
                    <PrettyDate dateString={fileEntity.date} />
                  ) : (
                    "N/A"
                  )}
                </Text>
                <Text as="p" variant="bodyMd">
                  <strong>{t("dateTo")} : </strong>{" "}
                  {fileEntity.dateTo ? (
                    <PrettyDate dateString={fileEntity.dateTo} />
                  ) : (
                    "N/A"
                  )}
                </Text>
                <Text as="p" variant="bodyMd">
                  <strong>{t("createdAt")} : </strong>{" "}
                  <PrettyDate
                    dateString={fileEntity.createdAt}
                    showTime={true}
                  />
                </Text>
                <Text as="p" variant="bodyMd">
                  <strong>{t("updatedAt")} : </strong>{" "}
                  <PrettyDate
                    dateString={fileEntity.updatedAt}
                    showTime={true}
                  />
                </Text>
              </TextContainer>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default FileContainer;
