import { Listbox, Combobox, Icon } from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  fetch as fetchCustomers,
  fetchById as fetchCustomerById,
} from "features/customers/customers.service"; // Ensure fetchCustomerById is implemented in your service
import { useState, useCallback, useEffect } from "react";

import { Option } from "types/Options";


const CustomerSelect = ({
  onChange,
  value,
  isRequired=false
}: {
  onChange: (value: string) => void;
  value: string;
  isRequired: boolean
}) => {
  const { t } = useTranslation();
  const { companyId } = useParams();

  const [selectedOption, setSelectedOption] = useState<string | undefined>();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchAndSetOptions = useCallback(
    async (searchText: string) => {
      setLoading(true);
      try {
        const { customersCollection } = await fetchCustomers(companyId, {
          textSearch: searchText,
        });
        setOptions(
          customersCollection.map((customer: any) => ({
            value: customer.id,
            label: `${customer.name} [${customer.externalCode}]`,
          }))
        );
      } finally {
        setLoading(false);
      }
    },
    [companyId]
  );

  const fetchAndSetInitialValue = useCallback(
    async (id: string) => {
      if (!id) return;
      setLoading(true);
      try {
        const customerEntity = await fetchCustomerById(companyId, id);
        const option = {
          value: customerEntity.id,
          label: `${customerEntity.name} [${customerEntity.externalCode}]`,
        };
        setSelectedOption(option.value);
        setInputValue(option.label);
        setOptions([option]);
      } finally {
        setLoading(false);
      }
    },
    [companyId]
  );

  useEffect(() => {
    if (value) {
      fetchAndSetInitialValue(value);
    }
  }, [value, fetchAndSetInitialValue]);

  const handleInputChange = useCallback(
    async (input: string) => {
      setInputValue(input);
      fetchAndSetOptions(input);
    },
    [fetchAndSetOptions]
  );

  const handleSelection = useCallback(
    (selected: string) => {
      const matchedOption = options.find((option) => option.value === selected);
      setSelectedOption(selected);
      setInputValue(matchedOption ? matchedOption.label : "");
      onChange(selected);
    },
    [options, onChange]
  );

  const optionsMarkup = options.map((option) => (
    <Listbox.Option
      key={option.value}
      value={option.value}
      selected={selectedOption === option.value}
      accessibilityLabel={option.label}
    >
      {option.label}
    </Listbox.Option>
  ));

  return (
    <div>
      <Combobox
        activator={
          <Combobox.TextField
            prefix={<Icon source={SearchIcon} />}
            onChange={handleInputChange}
            label={t("customer_select")}
            value={inputValue}
            placeholder={t("customer_search")}
            autoComplete="off"
            requiredIndicator={isRequired}
            error={isRequired && !selectedOption ? t("field_is_required") : ''}
          />
        }
      >
        <Listbox onSelect={handleSelection}>
          {loading ? (
            <Listbox.Loading accessibilityLabel="Loading" />
          ) : (
            optionsMarkup
          )}
        </Listbox>
      </Combobox>
    </div>
  );
};

export default CustomerSelect;
