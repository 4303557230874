import axios from "axios";
import { API_URL } from "../constants";

export const fetch = (companyId, filtersValue) => {
  const url = `${API_URL}/${companyId}/users/fetch`;
  return axios.post(url, { filtersValue }).then((response) => response.data);
};

// eslint-disable-next-line import/prefer-default-export
export const inviteToCompany = ({ userToInvite, companyId }) => {
  const url = `${API_URL}/${companyId}/users/inviteToCompany`;
  return axios.post(url, { userToInvite }).then((response) => response.data);
};

export const post = (data, companyId) => {
  const url = `${API_URL}/${companyId}/users/post`;
  return axios.post(url, data).then((response) => response.data);
};

export const fetchById = (userId, companyId) => {
  const url = `${API_URL}/${companyId}/users/${userId}`;
  return axios.get(url).then((response) => response.data);
};

export const getAuthCodeUrl = (companyId) => {
  const url = `${API_URL}/${companyId}/microsoft/get-auth-code-url`;
  return axios.get(url).then((response) => response.data);
}

export const disconnectMicrosoftSync = (companyId) => {
  const url = `${API_URL}/${companyId}/microsoft/delete-subscription`;
  return axios.get(url).then((response) => response.data);
}
