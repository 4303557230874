import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchById } from "services/user-select.service";
import { User } from "types/User";

// Define the types for your initial state
interface UserContainerState {
  isLoading: boolean;
  isError: boolean;
  errMsg: string;
  userEntity: User | null;
}

// Define the initial state
const initialState: UserContainerState = {
  isLoading: true,
  isError: false,
  errMsg: "",
  userEntity: null,
};

// Create the slice
const UserContainerSlice = createSlice({
  name: "User-Container",
  initialState,
  reducers: {
    setDestroy() {
      return initialState;
    },
    setLoading(state: UserContainerState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(
      state: UserContainerState,
      action: PayloadAction<{ error: string }>
    ) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setUserContainer(state: UserContainerState, action: PayloadAction<any>) {
      state.userEntity = action.payload;
      state.isLoading = false;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = UserContainerSlice;

// Extract and export each action creator by name
export const { setDestroy, setLoading, setError, setUserContainer } = actions;

// Export the reducer, either as a default or named export
export default reducer;

// Define the type for the dispatch function
export const fetchUserDetails =
  ({ userId, companyId }: { userId: string; companyId: string }) =>
  async (dispatch: any) => {
    dispatch(setLoading(true));
    try {
      const data = await fetchById(userId, companyId);
      dispatch(setUserContainer(data.userEntity));
    } catch (e) {
      const error = "not_fetch";
      dispatch(setError({ error }));
      return false;
    } finally {
      dispatch(setLoading(false));
    }
  };
