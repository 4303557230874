import { useEffect, useState, useCallback } from "react";
import { useAppDispatch } from "hooks";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Checkbox,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchCustomer } from "containers/customer/customer-container.slice";
import {
  createContact,
  updateContact,
  fetchContactById,
} from "services/contacts.service";
import Loading from "components/loading/Loading";

function ContactForm({ customer, id }: { customer: string; id?: string }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isAuthorizedToSign, setIsAuthorizedToSign] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(
    async (id: string) => {
      setIsLoading(true);
      const data = await fetchContactById(companyId, customer, id);
      setName(data.name);
      setPosition(data.position);
      setPhone(data.phone);
      setEmail(data.email);
      setIsAuthorizedToSign(data.isAuthorizedToSign);
      setIsLoading(false);
    },
    [companyId, customer]
  );

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id, fetchData]);

  if (!companyId) return null;

  const handleFormSubmit = async () => {
    const formData = {
      id,
      name,
      position,
      phone,
      email,
      isAuthorizedToSign,
    };
    const action = id ? updateContact : createContact;
    try {
      setIsLoading(true);
      await action(formData, customer, companyId);
      dispatch(fetchCustomer({ customerId: customer, companyId }));
      setSuccessMessage("ok");
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  if (isLoading && !name) {
    return <Loading />;
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div className="">
              <Banner tone="critical">{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div className="">
              <Banner tone="info">{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <TextField
          label={t("contact-name")}
          id="name"
          name="name"
          value={name}
          onChange={setName}
          autoComplete="off"
        />
        <TextField
          label={t("position")}
          id="position"
          name="position"
          value={position}
          onChange={setPosition}
          autoComplete="off"
        />
        <TextField
          label={t("phone")}
          id="phone"
          name="phone"
          value={phone}
          onChange={setPhone}
          autoComplete="off"
        />
        <TextField
          label={t("email")}
          id="email"
          name="email"
          type="email"
          value={email}
          onChange={setEmail}
          autoComplete="off"
        />
        <Checkbox
          label={t("is-authorized-to-sign")}
          checked={isAuthorizedToSign}
          onChange={setIsAuthorizedToSign}
        />
        <Button onClick={handleFormSubmit} disabled={!name} loading={isLoading}>
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
}

export default ContactForm;
