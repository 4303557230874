/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import { Text, Card, InlineGrid, Button } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

function ProductDetails({ product, onSubmit }) {
  const { t } = useTranslation();

  const productName = product.title ? (
    <Text as="p" variant="bodyMd">
      {product.title}
    </Text>
  ) : (
    <Text as="p" tone="subdued">
      {t("no_name_provided")}
    </Text>
  );
  const productNameEn = product.titleEn ? (
    <Text as="p" variant="bodyMd">
      {product.titleEn}
    </Text>
  ) : (
    <Text as="p" tone="subdued">
      {t("no_name_provided")}
    </Text>
  );
  const productSku = product.sku ? (
    <Text as="p" variant="bodyMd">
      {product.sku}
    </Text>
  ) : (
    <Text as="p" tone="subdued">
      {t("no_sku_provided")}
    </Text>
  );
  const productDescriptionNode = product.description ? (
    <Text as="p" variant="bodyMd">
      {product.description}
    </Text>
  ) : (
    <Text as="p" tone="subdued">
      {t("no_description_provided")}
    </Text>
  );

  const handleEdit = () => {
    // dispatch(setModalxComponent("CustomerForm"));
    // dispatch(setModalxComponentProps({ customer, onSubmit }));
  };

  return (
    <Card roundedAbove="sm">
      <InlineGrid columns="1fr auto">
        <Text as="h2" variant="headingSm">
          {t("customer_details")}
        </Text>
        <Button
          variant="plain"
          onClick={handleEdit}
          accessibilityLabel="Preview"
        >
          {t("edit")}
        </Button>
      </InlineGrid>
      {productName}
      {productNameEn}
      {productSku}
      {productDescriptionNode}
      {/* <BlockStack gap="200">
        <Box paddingBlockStart="200">
            <Text as="h3" variant="headingSm">
              {t("contacts")}
            </Text>
            {contactPersonNode}
            {emailNode}
            {phoneNode}
        </Box>
        <Box paddingBlock="200">
          <Text as="h3" variant="headingSm">
            {t("address")}
          </Text>
          <Text as="p" variant="bodyMd">
            {addressNode}
          </Text>
        </Box>
        <Box paddingBlock="200">
          <Text as="h3" variant="headingSm">
            {t("other")}
          </Text>
          <Text as="p" variant="bodyMd">
            {maxAllowedDebtNode}
          </Text>
          <Text as="p" variant="bodyMd" numeric>
            {overdueDebtNode}
          </Text>
          <Text as="p" variant="bodyMd" numeric>
            {debtNode}
          </Text>
          <Text as="p" variant="bodyMd" numeric>
            {turnoverNode}
          </Text>
          <Text as="p" variant="bodyMd">
            {assignedUserNode}
          </Text>
        </Box>
      </BlockStack> */}
    </Card>
  );
}

export default ProductDetails;
