import axios from "axios";
import { API_URL } from "../../constants";

export const logOut = () => {
  const url = `${API_URL}/logout`;
  return axios.get(url).then((response) => response.data);
};

export const authSession = () => {
  const url = `${API_URL}/session`;
  return axios.get(url).then((response) => response.data);
};

// eslint-disable-next-line import/prefer-default-export
export const sendLoginUrl = ({ email }) => {
  const url = `${API_URL}/auth/login/send-login-url`;
  return axios.post(url, { email }).then((response) => response.data);
};

export const signUp = (data) => {
  const url = `${API_URL}/signup`;
  return axios.post(url, data).then((response) => response.data);
};

export const restore = (data) => {
  const url = `${API_URL}/restore-request`;
  return axios.post(url, data).then((response) => response.data);
};

export const getInvitationData = (invitationKey) => {
  const url = `${API_URL}/get-invitation-data/${invitationKey}`;
  return axios.get(url).then((response) => response.data);
};

export const getRole = (companyId) => {
  const url = `${API_URL}/${companyId}/auth/role`;
  return axios.get(url).then((response) => response.data);
};
