import CompanySelect from "features/company-select/company-select";
import { isMobile, isBrowser } from "react-device-detect";

function Welcome() {
  return (
    <>
      {isMobile && <CompanySelect isList={true} orgChart={false} />}
      {isBrowser && <CompanySelect isList={false} orgChart={true} />}
    </>
  );
}

export default Welcome;
