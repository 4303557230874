import axios from "axios";
import { API_URL } from "constants";

// eslint-disable-next-line import/prefer-default-export
export const upload = (file, companyId) => {
  const url = `${API_URL}/${companyId}/files/upload`;
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadWithFormKey = (file, formKey) => {
  const url = `${API_URL}/customers/form-key/${formKey}/files/upload`;
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const fetchById = async (fileId, companyId) => {
  const url = `${API_URL}/${companyId}/files/${fileId}`;
  return axios.get(url).then((response) => response.data);
};

export const patchFile = async (data, companyId) => {
  const url = `${API_URL}/${companyId}/files/${data.id}/patch`;
  return axios.patch(url, data).then((response) => response.data);
};

export const fetchFileUrl = async (id, companyId) => {
  const url = `${API_URL}/${companyId}/files/download/${id}`;
  const response = await axios.get(url, { responseType: 'blob' });
  const blob = new Blob([response.data], { type: 'application/pdf' });
  const pdfUrl = URL.createObjectURL(blob);
  return pdfUrl;
};
