import axios from "axios";
import { API_URL } from "constants";

export const createSeries = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/series`;
  return axios.post(url, formData).then((response) => response.data);
};

export const updateSeries = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/series/${formData.id}`;
  return axios.patch(url, formData).then((response) => response.data);
};

export const fetchSeriesById = async (companyId, id) => {
  const url = `${API_URL}/${companyId}/series/${id}`;
  return axios.get(url).then((response) => response.data);
};

export const fetchSeries = async (companyId) => {
  const url = `${API_URL}/${companyId}/series`;
  return axios.get(url).then((response) => response.data);
};
