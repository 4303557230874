import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "constants.js";

/**
 * @typedef {Object} FetchCustomerArgs
 * @property {string} companyId
 * @property {string} customerId
 */

/**
 * @type {import("@reduxjs/toolkit").AsyncThunk<any, FetchCustomerArgs, {}>}
 */
export const fetchCustomer = createAsyncThunk(
  "customer/fetchCustomer",
  async ({ companyId, customerId }) => {
    const response = await axios.get(
      `${API_URL}/${companyId}/customers/${customerId}`
    );
    return response.data;
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async ({ companyId, customerId, data }) => {
    const response = await axios.put(
      `${API_URL}/${companyId}/customers/${customerId}`,
      data
    );
    return response.data;
  }
);

export const addContact = createAsyncThunk(
  "customer/addContact",
  async ({ companyId, customerId, data }) => {
    const response = await axios.post(
      `${API_URL}/${companyId}/customers/${customerId}/contacts`,
      data
    );
    return response.data;
  }
);

export const updateContact = createAsyncThunk(
  "customer/updateContact",
  async ({ companyId, customerId, contactId, data }) => {
    const response = await axios.put(
      `${API_URL}/${companyId}/customers/${customerId}/contacts/${contactId}`,
      data
    );
    return response.data;
  }
);

export const deleteContact = createAsyncThunk(
  "customer/deleteContact",
  async ({ companyId, customerId, contactId }) => {
    await axios.delete(
      `${API_URL}/${companyId}/customers/${customerId}/contacts/${contactId}`
    );
    return { contactId };
  }
);

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customer: null,
    isLoading: false,
    errMsg: null,
    successMessage: null,
  },
  reducers: {
    setDestroy: (state) => {
      state.customer = {};
      state.isLoading = false;
      state.errMsg = null;
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customer = action.payload;
      })
      .addCase(fetchCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.errMsg = action.error.message;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.customer = { ...state.customer, ...action.payload };
        state.successMessage = "Customer updated successfully";
      })
      .addCase(addContact.fulfilled, (state, action) => {
        state.customer.contacts = [...state.customer.contacts, action.payload];
        state.successMessage = "Contact added successfully";
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        state.customer.contacts = state.customer.contacts.map((contact) =>
          contact.id === action.payload.id ? action.payload : contact
        );
        state.successMessage = "Contact updated successfully";
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.customer.contacts = state.customer.contacts.filter(
          (contact) => contact.id !== action.payload.contactId
        );
        state.successMessage = "Contact removed successfully";
      });
  },
});

export const { setDestroy } = customerSlice.actions;
export default customerSlice.reducer;
