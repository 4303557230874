import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "configureStore";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Select,
  BlockStack,
  InlineStack,
  Checkbox,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchCustomField } from "containers/custom-field/custom-field-container.slice";
import {
  createCustomField,
  updateCustomField,
  fetchCustomFieldById,
} from "services/custom-field.service";

interface CustomFieldFormProps {
  // onSubmit?: (result: any) => void;
  id?: string;
}

const CustomFieldForm: React.FC<CustomFieldFormProps> = ({ id }) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const [label, setLabel] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [fieldType, setFieldType] = useState<string>("");
  const [fieldOptions, setFieldOptions] = useState<any>(null);
  const [isRequired, setIsRequired] = useState(false);
  const [isVisibleInList, setIsVisibleInList] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFormSubmit = async () => {
    const formData = {
      id,
      label,
      type,
      fieldType,
      fieldOptions,
      isRequired,
      isVisibleInList,
    };
    const action = id ? updateCustomField : createCustomField;
    try {
      setIsLoading(true);
      await action(formData, companyId);
      if (companyId) dispatch(fetchCustomField(companyId));
      setSuccessMessage("ok");
      setIsLoading(false);
    } catch (e) {
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  const typeOptions = [
    { label: " - ", value: "" },
    { label: t("files"), value: "file" },
    { label: t("user"), value: "user" },
    { label: t("customer"), value: "customer" },
  ];

  const fieldTypeOptions = [
    { label: " - ", value: "" },
    { label: t("text"), value: "text" },
    { label: t("number"), value: "number" },
    { label: t("date"), value: "date" },
    { label: t("select"), value: "select" },
    { label: t("checkbox"), value: "checkbox" },
  ];

  const fetchData = useCallback(
    async (id: string) => {
      const data = await fetchCustomFieldById(companyId!, id);
      setLabel(data.label);
      setType(data.type);
      setFieldType(data.fieldType);
      setFieldOptions(data.fieldOptions);
      setIsRequired(data.isRequired);
      setIsVisibleInList(data.isVisibleInList);
    },
    [companyId]
  );

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id, fetchData]);

  const updateFieldOptions = (index: number, newValue: string) => {
    const updatedOptions = [...fieldOptions];
    updatedOptions[index]["label"] = newValue;
    updatedOptions[index]["value"] = newValue;
    setFieldOptions(updatedOptions);
  };

  const addOptionToField = () => {
    setFieldOptions([...fieldOptions, { label: "", value: "" }]);
  };

  const removeOptionFromField = (index: string) => {
    const updatedOptions = fieldOptions.filter(
      (_: any, i: string) => i !== index
    );
    setFieldOptions(updatedOptions);
  };

  const fieldTypeHandler = (value: string) => {
    setFieldType(value);
    if (value === "select") {
      setFieldOptions([{ label: "", value: "" }]);
    } else {
      setFieldOptions(null);
    }
  };

  const handleCheckboxChange = useCallback(
    (setter: React.Dispatch<React.SetStateAction<boolean>>) =>
      (newChecked: boolean) =>
        setter(newChecked),
    []
  );

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div>
              <Banner tone="critical">{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div>
              <Banner tone="info">{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <TextField
          label={t("custom_field_label")}
          id="label"
          name="label"
          value={label}
          onChange={(value) => setLabel(value)}
          autoComplete="off"
        />
        <Select
          label={t("custom_field_object")}
          options={typeOptions}
          onChange={(value) => setType(value)}
          value={type}
        />
        <Select
          label={t("custom_field_type")}
          options={fieldTypeOptions}
          onChange={(value) => fieldTypeHandler(value)}
          value={fieldType}
        />
        {fieldType === "select" && (
          <BlockStack gap="100">
            {fieldOptions.map((option: any, optionIndex: any) => (
              <InlineStack key={optionIndex} blockAlign="end" gap="300">
                <div style={{ width: "48%" }}>
                  <TextField
                    label={`${t("custom_type_select_option")} ${optionIndex + 1}`}
                    value={option.label}
                    onChange={(value) => updateFieldOptions(optionIndex, value)}
                    autoComplete="off"
                  />
                </div>
                <Button
                  size="large"
                  onClick={() => removeOptionFromField(optionIndex)}
                >
                  {t("custom_remove_option")}
                </Button>
              </InlineStack>
            ))}
            <Button onClick={() => addOptionToField()}>
              {t("custom_add_option")}
            </Button>
          </BlockStack>
        )}
        <InlineStack gap="400">
          <Checkbox
            label={t('is_required')}
            checked={isRequired}
            onChange={handleCheckboxChange(setIsRequired)}
          />
          <Checkbox
            label={t('is_visible_in_list')}
            checked={isVisibleInList}
            onChange={handleCheckboxChange(setIsVisibleInList)}
          />
        </InlineStack>
        <Button
          onClick={handleFormSubmit}
          disabled={!label || !type || !fieldType}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
};

export default CustomFieldForm;
